import { useState } from "react";
import { t } from "@lingui/core/macro";
import { Trans } from "@lingui/react/macro";
import { AppointmentCancelReason } from "@/server-types";
import { useCalendarStore } from "../../../store/useCalendarStore";
import { getAppointmentCancelReasonOptions } from "@/constants/cancelReasons";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Checkbox,
  Select,
  SelectItem,
  Input,
} from "@heroui/react";

type CancelModalProps = {
  isOpen: boolean;
  onClose: () => void;
  appointmentId: number;
  isRepeating: boolean;
  isLinked: boolean;
};

export const AppointmentCancelModal = ({
  isOpen,
  onClose,
  appointmentId,
  isRepeating = false,
  isLinked = false,
}: CancelModalProps) => {
  const { hasCancelNotification, deleteAppointment } = useCalendarStore(
    (store) => store,
  );

  const cancelReasonOptions = getAppointmentCancelReasonOptions();

  const [deleteAllRepeating, setDeleteAllRepeating] = useState(false);
  const [deleteAllLinked, setDeleteAllLinked] = useState(true);
  const [sendCancelNotifications, setSendCancelNotifications] = useState(
    hasCancelNotification,
  );
  const [cancelReason, setCancelReason] =
    useState<AppointmentCancelReason>("NoReasonProvided");

  const [customCancelReason, setCustomCancelReason] = useState("");

  const handleConfirm = async () => {
    try {
      await deleteAppointment(
        appointmentId,
        deleteAllRepeating,
        deleteAllLinked,
        sendCancelNotifications,
        cancelReason,
        customCancelReason,
      );
      onClose();
    } catch (e) {
      console.error("Error deleting appointment:", e);
    }
  };

  return (
    <Modal
      classNames={{
        wrapper: "z-[9999999]",
      }}
      isDismissable={false}
      isOpen={isOpen}
      onOpenChange={(open) => {
        if (!open) onClose();
      }}
    >
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader className="flex flex-col gap-1">
              <Trans>Ali ste prepričani, da želite izbrisati termin?</Trans>
            </ModalHeader>
            <ModalBody>
              <div className="flex flex-col items-start gap-4">
                <Select
                  classNames={{
                    popoverContent: "z-[99999999]",
                  }}
                  label={t`Razlog za izbris`}
                  placeholder={t`Izberite razlog za preklic`}
                  selectedKeys={[cancelReason]}
                  onChange={(e) => {
                    setCancelReason(e.target.value as AppointmentCancelReason);
                  }}
                >
                  {cancelReasonOptions.map((option) => (
                    <SelectItem key={option.value}>{option.label}</SelectItem>
                  ))}
                </Select>

                {cancelReason === "Custom" && (
                  <Input
                    label={t`Vnesite razlog za odpoved`}
                    value={customCancelReason}
                    onValueChange={setCustomCancelReason}
                  />
                )}

                {isRepeating && (
                  <Checkbox
                    data-identifier="delete-all-appointments-checkbox"
                    isSelected={deleteAllRepeating}
                    onValueChange={setDeleteAllRepeating}
                  >
                    {t`Izbriši vse nadaljnje ponavljajoče termine?`}
                  </Checkbox>
                )}

                {isLinked && (
                  <Checkbox
                    isSelected={deleteAllLinked}
                    onValueChange={setDeleteAllLinked}
                  >
                    {t`Izbriši vse povezane termine?`}
                  </Checkbox>
                )}

                {hasCancelNotification && (
                  <Checkbox
                    isSelected={sendCancelNotifications}
                    onValueChange={setSendCancelNotifications}
                  >
                    {t`Pošlji obvestila?`}
                  </Checkbox>
                )}
              </div>
            </ModalBody>
            <ModalFooter>
              <div className="flex justify-end gap-2">
                <Button variant="flat" color="default" onPress={onClose}>
                  <Trans>Prekliči</Trans>
                </Button>
                <Button
                  color="primary"
                  onPress={handleConfirm}
                  isDisabled={!cancelReason}
                  data-identifier="confirm-cancel-appointment-button"
                >
                  <Trans>Izbriši</Trans>
                </Button>
              </div>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};

export default AppointmentCancelModal;
