import React, { useState } from "react";
import {
  Card,
  CardBody,
  Button,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
} from "@heroui/react";
import AppointmentActions from "../AppointmentActions";
import { Textarea } from "@heroui/input";
import {
  FiCalendar,
  FiMapPin,
  FiUser,
  FiFileText,
  FiAlertCircle,
  FiMessageCircle,
  FiInfo,
} from "react-icons/fi";

import { FaDoorOpen } from "react-icons/fa";
import { MdHistory } from "react-icons/md";
import dayjs from "dayjs";
import { Trans } from "@lingui/react/macro";
import AppointmentLog from "@/pages/Dashboard/Calendar/components/calendar/AppointmentLog/AppointmentLog";
import { AppointmentCancelModal } from "@/pages/Dashboard/Calendar/components/calendar/AppointmentActionsDropdown/AppointmentCancelModal";
import {
  getAppointmentCancelReasonOptions,
  getUserAppointmentCancelReasonOptions,
} from "@/constants/cancelReasons";

import { getLocalizedName } from "@/utils";
import { i18n } from "@lingui/core";
import RemoveCustomerFromAppointmentModal from "@/Components/AppointmentModal/components/RemoveCustomerFromAppointmentModal";

interface Appointment {
  service: string;
  startTime: string;
  location: string;
  comment: string;
  price: number;
  resources: string;
  users: string;
  notes?: string;
  cancellationReason?: string;
  userAppointmentId: number;
  appointmentId: number;
  isPreScheduled: boolean;
  repeatingAppointmentId?: number;
  linkedAppointmentId?: number;
  appointmentCancelReason?: string;
  customAppointmentCancelReason?: string;
  customerAppointmentCancelReason?: string;
  customCustomerAppointmentCancelReason?: string;
  appointmentIsDeleted?: boolean;
  userAppointmentIsDeleted?: boolean;
  customerId?: number;
  customerDidAttend?: number;
  customFields?: Array<
    | {
        type: "multi-select" | "checkbox";
        label: {
          [key: string]: string;
        };
        value: Array<{
          [key: string]: string;
          defaultLanguage: string;
        }>;
      }
    | {
        type: "radio" | "select";
        label: {
          [key: string]: string;
        };
        value: {
          [key: string]: string;
          defaultLanguage: string;
        };
      }
    | {
        type: "date" | "time";
        label: {
          [key: string]: string;
        };
        value: string;
      }
    | {
        type: "text";
        label: {
          [key: string]: string;
        };
        value: string;
      }
  >;
}

interface AppointmentListItemProps {
  appointment: Appointment;
  currencySymbol: string;
  updateUserAppointmentComment: (
    userAppointmentId: number,
    comment: string,
  ) => void;
  selectedAppointmentType: string;
  deleteUserAppointment: (userAppointmentId: number) => void;
  updateUserAppointmentAttendance: (
    userAppointmentId: number,
    attendance: number,
  ) => void;
  refreshCustomerData: () => void;
}

export const AppointmentListItem = ({
  appointment,
  currencySymbol,
  updateUserAppointmentComment,
  selectedAppointmentType,
  deleteUserAppointment,
  updateUserAppointmentAttendance,
  refreshCustomerData,
}: AppointmentListItemProps) => {
  const {
    isOpen: isCommentModalOpen,
    onOpen: onCommentModalOpen,
    onOpenChange: onCommentModalOpenChange,
  } = useDisclosure();

  const {
    isOpen: isLogModalOpen,
    onOpen: onLogModalOpen,
    onOpenChange: onLogModalOpenChange,
  } = useDisclosure();

  const {
    isOpen: isCancelModalOpen,
    onOpen: onCancelModalOpen,
    onOpenChange: onCancelModalOpenChange,
  } = useDisclosure();

  const {
    isOpen: isRemoveCustomerFromAppointmentModalOpen,
    onOpen: onRemoveCustomerFromAppointmentModalOpen,
    onOpenChange: onRemoveCustomerFromAppointmentModalOpenChange,
  } = useDisclosure();

  const [editedComment, setEditedComment] = useState(appointment.comment);

  const appointmentCancelReasonOptions = getAppointmentCancelReasonOptions();
  const appointmentCancelReasonLabel =
    appointment.appointmentCancelReason === "Custom"
      ? appointment.customAppointmentCancelReason
      : appointmentCancelReasonOptions.find(
          (opt) => opt.value === appointment.appointmentCancelReason,
        )?.label;

  const customerAppointmentCancelReasonOptions =
    getUserAppointmentCancelReasonOptions();

  const customerAppointmentCancelReasonLabel =
    appointment.customerAppointmentCancelReason === "Custom"
      ? appointment.customCustomerAppointmentCancelReason
      : customerAppointmentCancelReasonOptions.find(
          (opt) => opt.value === appointment.customerAppointmentCancelReason,
        )?.label;

  const { locale } = i18n;

  return (
    <Card className={"bg-default-50 shadow-sm"}>
      <CardBody className="p-4">
        <div className="flex items-start justify-between">
          <div className="flex-1">
            <div className="mb-2 flex flex-col gap-1 sm:flex-row sm:items-center sm:gap-2">
              <span className="font-medium">{appointment.service}</span>
              <div className="flex items-center gap-2">
                <span className="text-gray-600">
                  {appointment.price} {currencySymbol}
                </span>
              </div>
            </div>

            <div className="space-y-2 text-sm">
              <div className="flex items-center gap-2">
                <FiCalendar className="h-4 w-4 text-gray-500" />
                <span>
                  {dayjs(appointment.startTime)
                    .toDate()
                    .toLocaleString(locale, {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                    })}
                </span>
                <span className="mx-1 text-gray-500">•</span>
                <span>{dayjs(appointment.startTime).format("HH:mm")}</span>
              </div>

              <div className="flex items-center gap-2">
                <FiMapPin className="h-4 w-4 text-gray-500" />
                <span>{appointment.location}</span>
              </div>

              {appointment.resources.length > 0 && (
                <div className="flex items-center gap-2">
                  <FaDoorOpen className="h-4 w-4 text-gray-500" />
                  <span>{appointment.resources}</span>
                </div>
              )}

              {appointment.users.length > 0 && (
                <div className="flex items-center gap-2">
                  <FiUser className="h-4 w-4 text-gray-500" />
                  <span>{appointment.users}</span>
                </div>
              )}
              {appointment.comment && (
                <div className="flex items-start gap-2">
                  <FiFileText className="h-4 w-4 flex-shrink-0 text-gray-500" />
                  <span>
                    {appointment.comment.length > 100
                      ? `${appointment.comment.substring(0, 100)}...`
                      : appointment.comment}
                  </span>
                </div>
              )}

              {appointment.appointmentIsDeleted && (
                <div className="flex items-start gap-2 text-danger-600">
                  <FiAlertCircle className="h-4 w-4" />
                  <span>
                    <Trans>Razlog za odpoved:</Trans>{" "}
                    {appointmentCancelReasonLabel}
                  </span>
                </div>
              )}

              {!appointment.appointmentIsDeleted &&
                appointment.userAppointmentIsDeleted && (
                  <div className="flex items-start gap-2 text-danger-600">
                    <FiAlertCircle className="h-4 w-4" />
                    <span>
                      <Trans>Razlog za odstranitev stranke s termina:</Trans>{" "}
                      {customerAppointmentCancelReasonLabel}
                    </span>
                  </div>
                )}

              {appointment.customerDidAttend === -1 && (
                <div className="flex items-center gap-2 text-danger-600">
                  <FiAlertCircle className="h-4 w-4" />
                  <span>
                    <Trans>Stranka ni prišla na termin</Trans>
                  </span>
                </div>
              )}

              {appointment.isPreScheduled && (
                <div className="text-yellow-500 flex items-center gap-2">
                  <FiAlertCircle className="h-4 w-4" />
                  <span>
                    <Trans>Razpisan termin</Trans>
                  </span>
                </div>
              )}
            </div>
            {appointment.customFields &&
              appointment.customFields.length > 0 && (
                <div className="mt-10 flex flex-col">
                  <div className="font-medium">
                    <Trans>Dodatne informacije</Trans>
                  </div>
                  <div className="rounded bg-[#f8f8f8]">
                    <table className="w-full">
                      <tbody>
                        {appointment.customFields.map((extraDetail, index) => {
                          const inputElement = (() => {
                            switch (extraDetail.type) {
                              case "multi-select":
                              case "checkbox":
                                return (
                                  <div>
                                    {extraDetail.value
                                      .map(
                                        (v) =>
                                          v.defaultLanguage ||
                                          Object.values(v)[0],
                                      )
                                      .join(", ")}
                                  </div>
                                );
                              case "radio":
                              case "select":
                                return (
                                  <div>
                                    {extraDetail.value.defaultLanguage ||
                                      Object.values(extraDetail.value)[0]}
                                  </div>
                                );
                              case "date":
                                return (
                                  <div>
                                    {dayjs(
                                      extraDetail.value,
                                      "MM-DD-YYYYTHH:mm",
                                    )
                                      .toDate()
                                      .toLocaleString(locale, {
                                        day: "2-digit",
                                        month: "numeric",
                                        year: "numeric",
                                      })}
                                  </div>
                                );
                              case "time":
                                return (
                                  <div>
                                    {dayjs(
                                      extraDetail.value,
                                      "MM-DD-YYYYTHH:mm",
                                    )
                                      .toDate()
                                      .toLocaleString(locale, {
                                        hour: "2-digit",
                                        minute: "2-digit",
                                      })}
                                  </div>
                                );
                              case "text":
                                return <div>{extraDetail.value}</div>;
                            }
                          })();

                          return (
                            <tr key={index}>
                              <td className="py-2">
                                <div className="font-medium">
                                  {getLocalizedName(
                                    Object.entries(extraDetail.label).map(
                                      ([lang, value]) => ({
                                        language: lang,
                                        name: value as string,
                                      }),
                                    ),
                                  )}
                                </div>
                              </td>
                              <td className="py-0">{inputElement}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
          </div>

          <Button
            isIconOnly
            size="sm"
            variant="light"
            onPress={onCommentModalOpen}
          >
            <Modal
              isDismissable={false}
              isOpen={isCommentModalOpen}
              onOpenChange={onCommentModalOpenChange}
              // TODO: Remove z-index overrides after migrating appointment modal to HEROUI
              classNames={{
                backdrop: "z-[9999999]",
                wrapper: "z-[99999999]",
              }}
            >
              <ModalContent>
                {(onClose) => (
                  <>
                    <ModalHeader>
                      <Trans>Komentar</Trans>
                    </ModalHeader>
                    <ModalBody>
                      <Textarea
                        value={editedComment}
                        onChange={(e) => setEditedComment(e.target.value)}
                        rows={10}
                        cols={30}
                      />
                    </ModalBody>
                    <ModalFooter>
                      <Button
                        onPress={() => {
                          updateUserAppointmentComment(
                            appointment.userAppointmentId,
                            editedComment,
                          );
                          onClose();
                        }}
                      >
                        <Trans>Shrani</Trans>
                      </Button>
                    </ModalFooter>
                  </>
                )}
              </ModalContent>
            </Modal>
            <FiMessageCircle className="h-4 w-4" />
          </Button>
          <Button isIconOnly size="sm" variant="light" onPress={onLogModalOpen}>
            <MdHistory className="h-4 w-4" />
          </Button>
          <Modal
            isDismissable={false}
            isOpen={isLogModalOpen}
            onOpenChange={onLogModalOpenChange}
            // TODO: Remove z-index overrides after migrating appointment modal to HEROUI
            classNames={{
              backdrop: "z-[9999999]",
              wrapper: "z-[99999999]",
            }}
          >
            <ModalContent>
              <ModalHeader></ModalHeader>
              <ModalBody>
                <AppointmentLog appointmentId={appointment.appointmentId} />
              </ModalBody>
              <ModalFooter></ModalFooter>
            </ModalContent>
          </Modal>

          <AppointmentActions
            selectedAppointmentType={selectedAppointmentType}
            onClickDelete={() => {
              if (!appointment.isPreScheduled) {
                onCancelModalOpenChange();
              } else {
                onRemoveCustomerFromAppointmentModalOpen();
              }
            }}
            deleteUserAppointment={deleteUserAppointment}
            updateUserAppointmentAttendance={updateUserAppointmentAttendance}
            appointment={appointment}
          />

          <AppointmentCancelModal
            isOpen={isCancelModalOpen}
            onClose={() => {
              onCancelModalOpenChange();
              refreshCustomerData();
            }}
            appointmentId={appointment.appointmentId}
            isRepeating={appointment.repeatingAppointmentId ? true : false}
            isLinked={appointment.linkedAppointmentId ? true : false}
          />

          <RemoveCustomerFromAppointmentModal
            isOpen={isRemoveCustomerFromAppointmentModalOpen}
            onClose={() => {
              onRemoveCustomerFromAppointmentModalOpenChange();
              refreshCustomerData();
            }}
            appointmentId={appointment.appointmentId}
            userAppointmentId={appointment.userAppointmentId}
          />
        </div>
      </CardBody>
    </Card>
  );
};
