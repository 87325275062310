import { Trans } from "@lingui/react/macro";
import { t } from "@lingui/core/macro";
import { api } from "@/lib/api-client";
import { numberAndCountryCodeToFullPhoneNumber } from "@/Utilities";
import { cn } from "@/utils";
import { useDebouncedValue } from "@mantine/hooks";
import {
  useDisclosure,
  Button,
  Divider,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Input,
  Spinner,
  Tabs,
  Tab,
  RadioGroup,
  Radio,
  RadioProps,
} from "@heroui/react";
import { Building, Plus, Search, User } from "lucide-react";
import { useEffect, useState } from "react";
import { LimeModal } from "@/Components/NextBase/LimeModal";
import {
  ManageCustomerModal,
  EntityType,
} from "@/Components/NextBase/ManageCustomerModal";

export const CustomerModal = ({
  isOpen,
  handleClose,
  preSelectedCustomerId,
}: {
  isOpen: boolean;
  handleClose: (data?: { customerId?: number; companyId?: number }) => void;
  preSelectedCustomerId?: number;
}) => {
  const {
    isOpen: isCreateCustomerModalOpen,
    onOpen: onOpenCreateCustomerModal,
    onClose: onCloseCreateCustomerModal,
  } = useDisclosure();

  const [selectedEntityType, setSelectedEntityType] =
    useState<EntityType>("physical");

  const [searchQuery, setSearchQuery] = useState("");
  const [debouncedSearchQuery] = useDebouncedValue(searchQuery, 200);

  const [selectedEntityId, setSelectedEntityId] = useState<number | null>(null);

  const { data: searchedCustomers, isFetching: isSearchingCustomers } =
    api.customer.useGetCustomers({
      searchQuery:
        selectedEntityType === "physical" ? debouncedSearchQuery : undefined,
    });

  const { data: searchedCompanies, isFetching: isSearchingCompanies } =
    api.company.useGetCompanies({
      searchQuery:
        selectedEntityType === "legal" ? debouncedSearchQuery : undefined,
    });

  useEffect(() => {
    if (isOpen) {
      setSelectedEntityId(preSelectedCustomerId ?? null);
      setSearchQuery("");
    }
  }, [isOpen]);

  return (
    <>
      <LimeModal
        size="xl"
        isOpen={isOpen}
        onClose={handleClose}
        classNames={{
          closeButton: "m-2",
        }}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader>
                <Trans>Stranka</Trans>
              </ModalHeader>
              <ModalBody className="px-0">
                <div className="flex gap-2 px-4">
                  <Input
                    endContent={
                      isSearchingCustomers || isSearchingCompanies ? (
                        <Spinner size="sm" />
                      ) : (
                        <></>
                      )
                    }
                    startContent={<Search />}
                    placeholder={t`Išči v imeniku`}
                    variant="bordered"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.currentTarget.value)}
                  />
                  <Tabs
                    aria-label={t`Izberite tip stranke`}
                    selectedKey={selectedEntityType}
                    onSelectionChange={(type) =>
                      setSelectedEntityType(type as EntityType)
                    }
                    disableAnimation
                  >
                    <Tab
                      key={"physical"}
                      data-identifier={"assign-customer-physical-tab"}
                      title={
                        <div className="flex items-center space-x-2">
                          <User />
                        </div>
                      }
                    />
                    <Tab
                      key={"legal"}
                      data-identifier={"assign-customer-legal-tab"}
                      title={
                        <div className="flex items-center space-x-2">
                          <Building />
                        </div>
                      }
                    />
                  </Tabs>
                </div>

                <div className="max-h-[calc(100dvh-113px-60px-40px-60px-200px)] min-h-[50dvh] overflow-y-auto">
                  {selectedEntityType === "physical" && !searchedCustomers && (
                    <p className="mt-12 text-center text-foreground-400">
                      <Trans>Iščite stranke</Trans>
                    </p>
                  )}
                  {selectedEntityType === "legal" && !searchedCompanies && (
                    <p className="mt-12 text-center text-foreground-400">
                      <Trans>Iščite podjetja</Trans>
                    </p>
                  )}
                  {selectedEntityType === "physical" &&
                    !isSearchingCustomers &&
                    searchedCustomers &&
                    searchedCustomers.length === 0 && (
                      <p className="mt-12 text-center text-foreground-400">
                        <Trans>Ni strank</Trans>
                      </p>
                    )}
                  {selectedEntityType === "legal" &&
                    !isSearchingCompanies &&
                    searchedCompanies &&
                    searchedCompanies.length === 0 && (
                      <p className="mt-12 text-center text-foreground-400">
                        <Trans>Ni podjetij</Trans>
                      </p>
                    )}

                  <RadioGroup
                    classNames={{
                      wrapper: "gap-6 pl-4 pt-4",
                    }}
                    value={selectedEntityId?.toString()}
                    onValueChange={(value) =>
                      setSelectedEntityId(Number(value))
                    }
                  >
                    {selectedEntityType === "legal" &&
                      searchedCompanies?.map((company, index) => {
                        return (
                          <CustomerCustomRadio
                            value={company.companyId.toString()}
                            index={index}
                            key={company.companyId}
                          >
                            <p className="font-semibold">
                              {company.companyName}
                            </p>
                            <p className="text-sm">{company.taxNumber}</p>
                            <p className="text-sm">
                              {company.address}, {company.zipCode}{" "}
                              {company.city}
                            </p>
                          </CustomerCustomRadio>
                        );
                      })}
                    {selectedEntityType === "physical" &&
                      searchedCustomers?.map((customer, index) => {
                        const customerGsm = (() => {
                          if (!customer.gsm || !customer.countryCode)
                            return undefined;

                          const parsedPhoneNumber =
                            numberAndCountryCodeToFullPhoneNumber(
                              customer.gsm,
                              customer.countryCode,
                            );
                          return parsedPhoneNumber;
                        })();

                        return (
                          <CustomerCustomRadio
                            value={customer.customerId.toString()}
                            index={index}
                            key={customer.customerId}
                          >
                            <p className="font-semibold">
                              {customer.name} {customer.lastName}
                            </p>
                            {customer.email && (
                              <p className="text-sm">{customer.email}</p>
                            )}
                            {customerGsm && (
                              <p className="text-sm">{customerGsm}</p>
                            )}
                          </CustomerCustomRadio>
                        );
                      })}
                  </RadioGroup>
                </div>
              </ModalBody>
              <ModalFooter className="flex-col p-2">
                <Button
                  variant="light"
                  className="h-6 w-fit py-4 pl-0"
                  size="sm"
                  onPress={onOpenCreateCustomerModal}
                  data-identifier="create-customer-button"
                >
                  <Plus className="h-6 w-6 rounded-full bg-default p-1" />
                  <p>
                    <Trans>Dodaj novo stranko</Trans>
                  </p>
                </Button>

                <Divider />

                <Button
                  type="submit"
                  className="h-12 w-full font-semibold"
                  isDisabled={!selectedEntityId}
                  onPress={() => {
                    if (selectedEntityType === "physical") {
                      handleClose({
                        customerId: selectedEntityId || undefined,
                      });
                    } else if (selectedEntityType === "legal") {
                      handleClose({
                        companyId: selectedEntityId || undefined,
                      });
                    }
                  }}
                >
                  <Trans>Dodaj stranko na račun</Trans>
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </LimeModal>

      <ManageCustomerModal
        isOpen={isCreateCustomerModalOpen}
        handleClose={(data: { customerId?: number; companyId?: number }) => {
          onCloseCreateCustomerModal();

          if (data.customerId || data.companyId) {
            handleClose(data);
          }
        }}
        selectedEntityType={selectedEntityType}
      />
    </>
  );
};

const CustomerCustomRadio = (
  props: RadioProps & {
    index: number;
  },
) => {
  const { children, ...otherProps } = props;

  return (
    <Radio
      {...otherProps}
      classNames={{
        base: cn(
          "inline-flex bg-content1 hover:bg-content2 items-center justify-between",
          "flex-row-reverse w-full cursor-pointer gap-4 p-4  max-w-full rounded-xl bg-gray-50",
          "data-[selected=true]:outline-2 data-[selected=true]:outline data-[selected=true]:outline-gray-300",
        ),
      }}
    >
      {children}
    </Radio>
  );
};
