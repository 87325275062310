import React from "react";
import { Tabs, Tab, ScrollShadow } from "@heroui/react";
import { t } from "@lingui/core/macro";
import { Trans } from "@lingui/react/macro";
import { notifications } from "@mantine/notifications";
import { AppointmentListItem } from "./CustomerAppointmentItem";
import {
  deleteUserAppointment,
  updateUserAppointmentAttendance,
  updateUserAppointmentComment,
} from "../../../../../apiSchedule";

const AppointmentType = {
  UPCOMING: "UPCOMING",
  COMPLETED: "COMPLETED",
  DELETED: "DELETED",
};

export default function CustomerAppointments({
  customerData,
  refreshCustomerData,
  currencySymbol,
}) {
  const [selectedKey, setSelectedKey] = React.useState(
    AppointmentType.UPCOMING,
  );
  const [arrayToDisplay, setArrayToDisplay] = React.useState([]);

  const deleteUserAppointmentHandler = async (
    userAppointmentId,
    appointmentId,
  ) => {
    try {
      await deleteUserAppointment(userAppointmentId, appointmentId);
      notifications.show({ title: t`Termin izbrisan` });
      refreshCustomerData();
    } catch (e) {
      notifications.show({ title: t`Prišlo je do napake`, color: "red" });
    }
  };

  const updateUserAppointmentCommentHandler = async (
    userAppointmentId,
    comment,
  ) => {
    const response = await updateUserAppointmentComment(
      userAppointmentId,
      comment,
      localStorage.getItem("usr_token") || "",
    );
    if (response.status === 200) {
      setArrayToDisplay((prevArray) =>
        prevArray.map((appointment) =>
          appointment.userAppointmentId === userAppointmentId
            ? { ...appointment, comment }
            : appointment,
        ),
      );
      refreshCustomerData();
    }
    return response;
  };

  const updateUserAppointmentAttendanceHandler = async (id, attendance) => {
    const response = await updateUserAppointmentAttendance(id, attendance);
    if (response.status === 200) {
      setArrayToDisplay((prevArray) =>
        prevArray.map((appointment) =>
          appointment.userAppointmentId === id
            ? { ...appointment, customerDidAttend: attendance }
            : appointment,
        ),
      );
      refreshCustomerData();
    }
  };

  React.useEffect(() => {
    const appointmentsToDisplay = (() => {
      switch (selectedKey) {
        case AppointmentType.UPCOMING:
          return customerData.upcommingAppointments || [];
        case AppointmentType.COMPLETED:
          return customerData.completedAppointments || [];
        case AppointmentType.DELETED:
          return customerData.deletedAppointments || [];
        default:
          return [];
      }
    })();
    setArrayToDisplay(appointmentsToDisplay);
  }, [customerData, selectedKey]);

  return (
    <div className="mb-6 flex w-full flex-col items-center">
      <ScrollShadow
        orientation="horizontal"
        className="w-full max-w-full"
        hideScrollBar
      >
        <Tabs
          selectedKey={selectedKey}
          color="primary"
          disableAnimation={true}
          onSelectionChange={setSelectedKey}
          variant="underlined"
          classNames={{
            base: "w-full overflow-x-auto ",
            tabList:
              "relative flex w-full gap-2 px-4 md:px-6 md:justify-center",
            tab: "max-w-fit flex-none px-4 py-2 text-sm md:text-base",
            tabContent: "group-data-[selected=true]:text-black",
          }}
        >
          <Tab
            key={AppointmentType.UPCOMING}
            title={<Trans>Prihajajoči termini</Trans>}
          >
            <div className="p-0 md:p-6">
              <div className="max-h-[calc(100vh-300px)] space-y-4 overflow-y-auto">
                {arrayToDisplay.length === 0 ? (
                  <p className="text-center text-gray-500">
                    <Trans>Ni terminov</Trans>
                  </p>
                ) : (
                  arrayToDisplay.map((appointment) => (
                    <AppointmentListItem
                      key={appointment.userAppointmentId}
                      updateUserAppointmentComment={
                        updateUserAppointmentCommentHandler
                      }
                      deleteUserAppointment={deleteUserAppointmentHandler}
                      updateUserAppointmentAttendance={
                        updateUserAppointmentAttendanceHandler
                      }
                      currencySymbol={currencySymbol}
                      appointment={appointment}
                      refreshCustomerData={refreshCustomerData}
                    />
                  ))
                )}
              </div>
            </div>
          </Tab>
          <Tab
            key={AppointmentType.COMPLETED}
            title={<Trans>Pretekli termini</Trans>}
          >
            <div className="p-0 md:p-6">
              <div className="max-h-[calc(100vh-300px)] space-y-4 overflow-y-auto">
                {arrayToDisplay.length === 0 ? (
                  <p className="text-center text-gray-500">
                    <Trans>Ni terminov</Trans>
                  </p>
                ) : (
                  arrayToDisplay.map((appointment) => (
                    <AppointmentListItem
                      key={appointment.userAppointmentId}
                      updateUserAppointmentComment={
                        updateUserAppointmentCommentHandler
                      }
                      deleteUserAppointment={deleteUserAppointmentHandler}
                      updateUserAppointmentAttendance={
                        updateUserAppointmentAttendanceHandler
                      }
                      currencySymbol={currencySymbol}
                      appointment={appointment}
                      refreshCustomerData={refreshCustomerData}
                    />
                  ))
                )}
              </div>
            </div>
          </Tab>
          <Tab
            key={AppointmentType.DELETED}
            title={<Trans>Izbrisani termini</Trans>}
          >
            <div className="p-0 md:p-6">
              <div className="max-h-[calc(100vh-300px)] space-y-4 overflow-y-auto">
                {arrayToDisplay.length === 0 ? (
                  <p className="text-center text-gray-500">
                    <Trans>Ni terminov</Trans>
                  </p>
                ) : (
                  arrayToDisplay.map((appointment) => (
                    <AppointmentListItem
                      key={appointment.userAppointmentId}
                      updateUserAppointmentComment={
                        updateUserAppointmentCommentHandler
                      }
                      deleteUserAppointment={deleteUserAppointmentHandler}
                      updateUserAppointmentAttendance={
                        updateUserAppointmentAttendanceHandler
                      }
                      currencySymbol={currencySymbol}
                      appointment={appointment}
                      refreshCustomerData={refreshCustomerData}
                    />
                  ))
                )}
              </div>
            </div>
          </Tab>
        </Tabs>
      </ScrollShadow>
    </div>
  );
}
