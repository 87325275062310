import { api } from "@/lib/api-client";
import { Color } from "@/types/colors";
import { TextVariant } from "@/types/text-variants";
import { toCents } from "@/utils";
import { Text } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useEffect } from "react";
import {
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  Divider,
  Button,
} from "@heroui/react";
import { Trans } from "@lingui/react/macro";
import { t } from "@lingui/core/macro";
import { LimeModal } from "@/Components/NextBase/LimeModal";
import { LimeSelect } from "@/Components/NextBase/LimeSelect";
import { LimeNumberInput } from "@/Components/LimeNumberInput";
import { StatusBadge } from "./StatusBadge";
import { LimeAlert } from "@/Components/NextBase/LimeAlert";

type RefundForm = {
  amount: number;
  reason: "requested_by_customer" | "duplicate";
};

export const PaymentDetailsModal = ({
  userAppointmentId,
  onClose,
}: {
  userAppointmentId?: number;
  onClose: ({ refetchData }: { refetchData?: boolean }) => void;
}) => {
  const {
    data: payment,
    isSuccess,
    isPending,
    processedErrorMessage: getPaymentErrorMessage,
  } = api.stripe.useGetPaymentForOverview(userAppointmentId);

  const {
    mutateAsync: sendEmail,
    isPending: isSendingEmail,
    processedErrorMessage: sendEmailErrorMessage,
  } = api.stripe.usePostSendStripeInvoiceViaEmail();

  const refundForm = useForm<RefundForm>({
    mode: "controlled",
    validate: {
      amount: (value) => {
        if (value < 1) {
          return t`Znesek mora biti večji od 0`;
        }

        if (value > (payment?.amountCents || 0)) {
          return t`Znesek ne sme biti večji od maksimalnega zneska za vračilo`;
        }

        return true;
      },
    },
  });

  const { mutateAsync: refundPayment, isPending: isRefunding, processedErrorMessage: refundPaymentErrorMessage } =
    api.stripe.usePostPaymentRefund();

  const handleRefundPayment = async () => {
    if (!payment || !payment.paymentIntentId) return;

    try {
      await refundPayment({
        intentId: payment.paymentIntentId,
        body: {
          ...refundForm.getValues(),
          amountCents: toCents(refundForm.getValues().amount),
        },
      });

      onClose({ refetchData: true });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!payment) {
      return;
    }

    refundForm.setValues({
      amount: payment.maxNoLossRefundAmountCents / 100,
      reason: "requested_by_customer",
    });
  }, [payment]);

  return (
    <LimeModal
      isOpen={userAppointmentId != null}
      size="xl"
      onClose={() => onClose({})}
    >
      <ModalContent>
        <ModalHeader>
          <Trans>Podatki termina</Trans>
        </ModalHeader>
        <ModalBody>
          <LimeAlert message={getPaymentErrorMessage} color={"danger"} />

          {isPending && (
            <div className="flex justify-center">
              <Spinner />
            </div>
          )}

          {isSuccess && (
            <div className="flex flex-col">
              <Text variant={TextVariant.Body}>
                <Text variant={TextVariant.Caption} span>
                  <Trans>Datum in čas</Trans>:
                </Text>{" "}
                {payment.appointmentStartTime}
              </Text>

              <Text variant={TextVariant.Body}>
                <Text variant={TextVariant.Caption} span>
                  <Trans>Lokacija</Trans>:
                </Text>{" "}
                {payment.location.label} - {payment.location.address},{" "}
                {payment.location.city}
              </Text>

              {payment.resources.length > 0 && (
                <>
                  <Text variant={TextVariant.Body}>
                    <Text variant={TextVariant.Caption} span>
                      <Trans>Sredstva</Trans>:
                    </Text>{" "}
                    {payment.resources
                      .map((resource) => resource.name)
                      .join(", ")}
                  </Text>
                </>
              )}

              <Text variant={TextVariant.Body}>
                <Text variant={TextVariant.Caption} span>
                  <Trans>Storitev</Trans>:
                </Text>{" "}
                {payment.services.map((service) => (
                  <Text key={service.name} variant={TextVariant.Body}>
                    {service.name} {service.users.length > 0 && "-"}{" "}
                    {service.users.map(
                      (user) => `${user.name} ${user.lastName}`,
                    )}
                  </Text>
                ))}
              </Text>

              <Divider className="mt-2" />

              <Text variant={TextVariant.BodyEmphasized} mb={"xs"} mt={"md"}>
                <Trans>Podatki stranke</Trans>
              </Text>
              <Text variant={TextVariant.Body}>
                {payment.customer.name} {payment.customer.lastName}
              </Text>

              {payment.customer.email && (
                <a href={`mailto:${payment.customer.email}`}>
                  <Text variant={TextVariant.Body}>
                    {payment.customer.email}
                  </Text>
                </a>
              )}

              {payment.customer.phone && (
                <a href={`tel:${payment.customer.phone}`}>
                  <Text variant={TextVariant.Body}>
                    {payment.customer.phone}
                  </Text>
                </a>
              )}

              <Button
                color="primary"
                className="mt-1 w-fit"
                size="sm"
                isLoading={isSendingEmail}
                onPress={() => {
                  if (!payment.paymentIntentId) {
                    return;
                  }

                  sendEmail({ intentId: payment.paymentIntentId });
                }}
              >
                Ponovno pošlji e-mail
              </Button>
              <LimeAlert message={sendEmailErrorMessage} color={"danger"} />

              <Text variant={TextVariant.BodyEmphasized} mb={"xs"} mt={"md"}>
                <Trans>Plačilo</Trans>
              </Text>
              <Text variant={TextVariant.Body}>
                <Text variant={TextVariant.Caption} span>
                  <Trans>Identifikacija</Trans>:
                </Text>{" "}
                {payment.payment}
              </Text>
              <Text variant={TextVariant.Body}>
                <Text variant={TextVariant.Caption} span>
                  <Trans>Datum plačila</Trans>:
                </Text>{" "}
                {payment.paymentDate}
              </Text>
              <div className="flex items-center">
                <Text variant={TextVariant.Caption} span mr={"xs"}>
                  <Trans>Status:</Trans>
                </Text>
                <StatusBadge payment={payment} />
                {payment.status !== "succeeded" &&
                  !payment.refundAmount &&
                  payment.status !== "requires_payment_method" && (
                    <Text span ml={"xs"} variant={TextVariant.Caption}>
                      ({payment.status})
                    </Text>
                  )}
                {payment.refundAmount && (
                  <Text span ml={"xs"} variant={TextVariant.Caption}>
                    - {payment.refundAmount}
                  </Text>
                )}
              </div>
              {payment.status === "succeeded" && (
                <Text variant={TextVariant.Body}>
                  <Text variant={TextVariant.Caption} span>
                    <Trans>Znesek</Trans>:
                  </Text>{" "}
                  {payment.amountFormatted}
                </Text>
              )}

              {payment.status === "succeeded" && (
                <div className="flex flex-col gap-1">
                  <Divider className="mt-2" />

                  <Text variant={TextVariant.BodyEmphasized} mt={"xs"}>
                    <Trans>Vračilo</Trans>
                  </Text>

                  <LimeNumberInput
                    label={t`Znesek`}
                    size="sm"
                    action={{
                      text: t`Celoten znesek`,
                      onClick: () => {
                        refundForm.setValues({
                          ...refundForm.getValues(),
                          amount: payment.amountCents / 100,
                        });
                      },
                      active:
                        refundForm.getValues().amount ===
                        payment.amountCents / 100,
                    }}
                    {...refundForm.getInputProps("amount")}
                    max={payment.amountCents / 100}
                    min={0.01}
                    disabled={isRefunding}
                    allowDecimal
                    decimalScale={2}
                    fixedDecimalScale
                    variant="filled"
                    rightSection={payment.currencySymbol}
                  />

                  <LimeSelect
                    size="sm"
                    label={t`Razlog`}
                    items={[
                      {
                        label: t`Zahtevano s strani stranke`,
                        key: "requested_by_customer",
                      },
                      {
                        label: t`Podvojeno plačilo`,
                        key: "duplicate",
                      },
                    ]}
                    {...refundForm.getInputProps("reason")}
                    isLoading={isRefunding}
                  />

                  {refundForm.getValues().amount >
                    payment.maxNoLossRefundAmountCents / 100 && (
                    <Text
                      c={Color.Error}
                      variant={TextVariant.Caption}
                      mt={"xs"}
                    >
                      <Trans>
                        Razlika med zneskom vračila in maksimalnim zneskom, ki
                        ga lahko vrnete brez izgube (
                        {payment.maxNoLossRefundAmountCentsFormatted}), bo vzeta
                        iz vašega računa.
                      </Trans>
                    </Text>
                  )}

                  <Button
                    isLoading={isRefunding}
                    onPress={handleRefundPayment}
                    color="primary"
                  >
                    {refundForm.getValues().amount === payment.amountCents / 100
                      ? t`Vrni celoten znesek`
                      : t`Vrni delni znesek`}
                  </Button>

                  <LimeAlert message={refundPaymentErrorMessage} color={"danger"} />
                </div>
              )}
            </div>
          )}
        </ModalBody>
        <ModalFooter></ModalFooter>
      </ModalContent>
    </LimeModal>
  );
};
