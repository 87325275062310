import { Chip } from "@heroui/react";
import { Trans } from "@lingui/react/macro";
import { Check, CornerUpLeft, OctagonAlert } from "lucide-react";

export const StatusBadge = ({
  payment,
}: {
  payment: {
    status: string | null;
    refundAmount: string | null;
  };
}) => {
  if (payment.refundAmount != null) {
    return (
      <Chip
        startContent={<CornerUpLeft size={16} />}
        variant="flat"
        color={"primary"}
        size="sm"
      >
        <Trans>Povrjeno</Trans>
      </Chip>
    );
  }

  if (payment.status === "requires_payment_method") {
    return (
      <Chip
        startContent={<OctagonAlert size={16} />}
        variant="flat"
        color={"warning"}
        size="sm"
      >
        <Trans>V obdelavi</Trans>
      </Chip>
    );
  }

  if (payment.status === "succeeded") {
    return (
      <Chip
        startContent={<Check size={16} />}
        variant="flat"
        color={"success"}
        size="sm"
      >
        <Trans>Uspešno</Trans>
      </Chip>
    );
  }

  return (
    <Chip
      startContent={<OctagonAlert size={16} />}
      variant="flat"
      color={"danger"}
      size="sm"
    >
      <Trans>Neuspešno</Trans>
    </Chip>
  );
};
