import {
  GetQzTrayClientCertificate,
  GetCustomerFiles,
  GetCustomerFileUploadPresignedUrl,
  PostCompleteCustomerFileUpload,
  DeleteCustomerFile,
  GetCustomerFileDownloadPresignedUrl,
} from "@/server-types";
import { get, post, del } from "../request-helpers";
import { useCustomMutation } from "../custom-mutation";
import { useCustomQuery } from "../custom-query";

export const useGetCustomerFileDownloadPresignedUrl = () => {
  return useCustomMutation({
    mutationKey: ["getCustomerFileDownloadPresignedUrl"],
    mutationFn: ({
      id: customerId,
      fileId,
      query,
    }: GetCustomerFileDownloadPresignedUrl["params"] & {
      query?: GetCustomerFileDownloadPresignedUrl["query"];
    }) =>
      get<GetCustomerFileDownloadPresignedUrl>(
        `/storage/file/customer/${customerId}/file/${fileId}/download`,
        query,
      ),
  });
};

export const useDeleteCustomerFile = () => {
  return useCustomMutation({
    mutationKey: ["deleteCustomerFile"],
    mutationFn: ({ id: customerId, fileId }: DeleteCustomerFile["params"]) =>
      del(`/storage/file/customer/${customerId}/file/${fileId}`),
  });
};

export const useDownloadQzTrayClientCertificate = () => {
  return useCustomMutation({
    mutationKey: ["downloadQzTrayClientCertificate"],
    mutationFn: () =>
      get<GetQzTrayClientCertificate>(
        "/storage/file/qz-tray/client-certificate",
        undefined,
      ),
  });
};

export const useGetCustomerFiles = (
  customerId: number,
  query: GetCustomerFiles["query"],
) => {
  return useCustomQuery({
    queryKey: ["getCustomerFiles", customerId, query],
    queryFn: () =>
      get<GetCustomerFiles>(`/storage/file/customer/${customerId}`, query),
  });
};

export const useGetCustomerFileUploadPresignedUrl = () => {
  return useCustomMutation({
    mutationKey: ["getCustomerFileUploadPresignedUrl"],
    mutationFn: ({
      customerId,
      query,
    }: {
      customerId: number;
      query: GetCustomerFileUploadPresignedUrl["query"];
    }) =>
      get<GetCustomerFileUploadPresignedUrl>(
        `/storage/file/customer/${customerId}/upload`,
        query,
      ),
  });
};

export const useCompleteCustomerFileUpload = () => {
  return useCustomMutation({
    mutationKey: ["completeCustomerFileUpload"],
    mutationFn: ({
      customerId,
      body,
    }: {
      customerId: number;
      body: PostCompleteCustomerFileUpload["body"];
    }) =>
      post<PostCompleteCustomerFileUpload>(
        `/storage/file/customer/${customerId}/upload/complete`,
        body,
      ),
  });
};
