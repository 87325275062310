import { FaAngleDown, FaEdit } from "react-icons/fa";
import useUserPermissionsStore from "../../../../stores/useUserPermissionsStore";
import { Menu, Flex, Text } from "@mantine/core";
import { Button } from "@heroui/react";
import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { useEffect, useState } from "react";
import { FiMoreVertical } from "react-icons/fi";
const AppointmentActions = (props) => {
  const { onClickDelete, updateUserAppointmentAttendance, appointment } = props;
  useLingui();

  const permissions = useUserPermissionsStore((state) => state.permissions);

  const [menuOpened, setMenuOpened] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setMenuOpened(false);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      <Flex direction="column" align="flex-end" ml={"auto"} gap={"md"}>
        <Menu
          shadow="md"
          radius={"lg"}
          opened={menuOpened}
          onChange={setMenuOpened}
        >
          <Menu.Target>
            <Button isIconOnly size="sm" variant="light">
              <FiMoreVertical className="h-4 w-4" />
            </Button>
          </Menu.Target>
          <Menu.Dropdown>
            {permissions.manage_appointments && (
              <>
                <Menu.Item
                  color="red"
                  onClick={(e) => {
                    onClickDelete();
                  }}
                >
                  <Trans>Izbriši termin</Trans>
                </Menu.Item>
                <Menu.Item
                  color="orange"
                  onClick={() => {
                    const newStatus =
                      appointment.customerDidAttend === 1 ? -1 : 1;
                    updateUserAppointmentAttendance(
                      appointment.userAppointmentId,
                      newStatus,
                    );
                  }}
                >
                  {appointment.customerDidAttend === 1 ? (
                    <Trans>Označi neprihod</Trans>
                  ) : (
                    <Trans>Razveljavi neprihod</Trans>
                  )}
                </Menu.Item>
              </>
            )}
          </Menu.Dropdown>
        </Menu>
      </Flex>
    </>
  );
};

export default AppointmentActions;
