import { t } from "@lingui/core/macro";
import { Trans } from "@lingui/react/macro";
import { Divider } from "@heroui/react";

type Appointment = {
  customerDidAttend: number;
  price: number;
  discountAmount?: number;
  discountType?: "percentage" | null;
  service: string;
};

type ServiceAnalytics = {
  name: string;
  visits: number;
};

type AnalyticsProps = {
  completedAppointments: Appointment[];
  currencySymbol: string;
};

const Analytics = ({
  completedAppointments,
  currencySymbol,
}: AnalyticsProps) => {
  const didAttendCount = completedAppointments.filter(
    (appointment) => appointment.customerDidAttend === 1,
  ).length;
  const didntAttendCount = completedAppointments.filter(
    (appointment) => appointment.customerDidAttend === -1,
  ).length;

  const revenueSum = completedAppointments
    .filter((appointment) => appointment.customerDidAttend === 1)
    .reduce((acc, appointment) => {
      const basePrice = appointment.price || 0;
      const discountAmount = appointment.discountAmount || 0;
      const discountType = appointment.discountType || null;
      const discountedPrice =
        discountType === "percentage"
          ? basePrice - basePrice * (discountAmount / 100)
          : basePrice - discountAmount;
      return acc + discountedPrice;
    }, 0);

  const averageRevenue = revenueSum / completedAppointments.length;

  const serviceAnalyticsData: ServiceAnalytics[] = [];
  for (const appointment of completedAppointments) {
    if (
      !serviceAnalyticsData.some((item) => item.name === appointment.service)
    ) {
      serviceAnalyticsData.push({
        name: appointment.service || "N/A",
        visits: 1,
      });
    } else {
      serviceAnalyticsData.find((item) => item.name === appointment.service)!
        .visits++;
    }
  }

  const attendancePercentage = (count: number) => {
    const percentage = (count / completedAppointments.length) * 100;
    return isNaN(percentage) ? null : percentage.toFixed(1);
  };

  return (
    <div className="space-y-6 p-4">
      {/* Visit Statistics */}
      <div className="space-y-2">
        <h4 className="text-sm font-medium text-default-700">
          <Trans>Obiski</Trans>
        </h4>
        <div className="grid grid-cols-3 gap-3">
          <div className="rounded-lg bg-default-50 p-3">
            <p className="text-sm text-default-600">
              <Trans>Skupno obiskov</Trans>
            </p>
            <p className="text-lg font-semibold">
              {completedAppointments.length}
            </p>
          </div>
          <div className="rounded-lg bg-default-50 p-3">
            <p className="text-sm text-default-600">
              <Trans>Prisoten</Trans>
            </p>
            <p className="text-lg font-semibold">{didAttendCount}</p>
            {attendancePercentage(didAttendCount) && (
              <p className="text-xs text-default-400">
                ({attendancePercentage(didAttendCount)}%)
              </p>
            )}
          </div>
          <div className="rounded-lg bg-default-50 p-3">
            <p className="text-sm text-default-600">
              <Trans>Neprihodi</Trans>
            </p>
            <p className="text-lg font-semibold">{didntAttendCount}</p>
            {attendancePercentage(didntAttendCount) && (
              <p className="text-xs text-default-400">
                ({attendancePercentage(didntAttendCount)}%)
              </p>
            )}
          </div>
        </div>
      </div>

      <Divider />

      {/* Earnings Section */}
      <div className="space-y-2">
        <h4 className="text-sm font-medium text-default-700">
          <Trans>Prihodki</Trans>
        </h4>
        <div className="grid grid-cols-2 gap-3">
          <div className="rounded-lg bg-default-50 p-3">
            <p className="text-sm text-default-600">
              <Trans>Skupni prihodki</Trans>
            </p>
            <p className="text-lg font-semibold">
              {revenueSum.toFixed(2)} {currencySymbol}
            </p>
          </div>
          <div className="rounded-lg bg-default-50 p-3">
            <p className="text-sm text-default-600">
              <Trans>Povprečni prihodek na obisk</Trans>
            </p>
            <p className="text-lg font-semibold">
              {isNaN(averageRevenue)
                ? `0 ${currencySymbol}`
                : `${averageRevenue.toFixed(2)} ${currencySymbol}`}
            </p>
          </div>
        </div>
      </div>

      <Divider />

      {/* Services Section */}
      <div className="space-y-2">
        <h4 className="text-sm font-medium text-default-700">
          <Trans>Priljubljene storitve</Trans>
        </h4>
        <div className="rounded-lg bg-default-50 p-3">
          {serviceAnalyticsData.length > 0 ? (
            serviceAnalyticsData.map((service, index) => (
              <div key={index} className="flex justify-between gap-4">
                <span className="truncate text-sm text-default-600">
                  {service.name}
                </span>
                <span className="shrink-0 text-sm font-medium">
                  {service.visits} {t`obiskov`}
                </span>
              </div>
            ))
          ) : (
            <p className="text-sm text-default-600">
              <Trans>Ni podatkov o obiskih</Trans>
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Analytics;
