import { t } from "@lingui/core/macro";
import { Trans } from "@lingui/react/macro";
import { LimePagination } from "@/Components/LimePagination";
import LimePhoneInputField from "@/Components/LimePhoneInputField";
import { PageHeader } from "@/Components/PageHeader/PageHeader";
import { useLingui } from "@lingui/react";
import {
  Badge,
  Button,
  Flex,
  Modal,
  Select,
  Space,
  Text,
  TextInput,
  Textarea,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { MdOutlineAdd, MdBlock } from "react-icons/md";
import {
  RiGitMergeLine,
  RiGroupFill,
  RiMailSendLine,
  RiSearch2Line,
} from "react-icons/ri";
import EmptyState from "../../../Components/EmptyState";
import LimeDrawer from "../../../Components/LimeDrawer/LimeDrawer";
import PageContentTable from "../../../Components/PageContentTable/PageContentTable";
import { useCustomers } from "../../../hooks/useCustomers";
import useFeature from "../../../hooks/useFeature";
import { BulkSMSModal } from "./BulkSMSModal";
import { Actions, ActionsIcons, ActionsRow } from "./Completed.styled";
import CustomerCard from "./CustomerCard/CustomerCard";
import useModalCustomer from "./useModalCustomer";
import { LimeLocalePicker } from "@/Components/NextBase/LimeLocalePicker";
import {
  Modal as HeroUIModal,
  ModalBody,
  ModalContent,
  ModalHeader,
} from "@heroui/react";
import { LimeModal } from "@/Components/NextBase/LimeModal";

const Completed = () => {
  const {
    customers,
    isLoading,
    updateCustomers,
    toggleSelectedCustomer,
    mergeCustomers,
    paginationPage,
    setPaginationPage,
    pagination,
    searchQuery,
    setSearchQuery,
    perPage,
    setPerPage,
    sortBy,
    setSortBy,
    globalSelectedCustomerData,
    setGlobalSelectedCustomerData,
    refreshCustomerData,
  } = useCustomers();

  const mergeForm = useForm({
    initialValues: {
      name: null,
      lastName: null,
      gsm: null,
      email: null,
      notes: null,
    },
  });

  const isMobile = useMediaQuery("(max-width: 768px)");
  const [selectedCustomerId, setSelectedCustomerId] = useState(null);
  useLingui();
  const [form, modalOpened, toggleModal, setModalOpened, submitCustomerForm] =
    useModalCustomer();

  const [mergeModalOpened, { open: openMergeModal, close: closeMergeModal }] =
    useDisclosure(false);
  const [
    bulkSMSModalOpened,
    { open: openBulkSMSModal, close: closeBulkSMSModal },
  ] = useDisclosure(false);

  const { isFeatureEnabled } = useFeature();

  useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const selectedUserId = params.get("user");

    if (selectedUserId) {
      selectRow(selectedUserId);
      window.history.replaceState({}, "", `${window.location.pathname}`);
    }
  }, []);

  const selectRow = (customerId) => {
    setSelectedCustomerId(customerId);
  };

  const handleMergeSubmit = (values) => {
    mergeCustomers(values);
    setGlobalSelectedCustomerData([]);
    closeMergeModal();
  };

  const removeDuplicateValues = (array) => {
    return array.filter(
      (item, index, self) =>
        index === self.findIndex((t) => t.value === item.value),
    );
  };

  return (
    <>
      <Modal
        opened={mergeModalOpened}
        onClose={closeMergeModal}
        title={t`Podatki združenega uporabnika`}
        centered
      >
        <form
          style={{ display: "contents" }}
          onSubmit={mergeForm.onSubmit(handleMergeSubmit)}
        >
          <Flex direction={"column"} gap={"md"}>
            <Select
              w={"100%"}
              ta={"left"}
              label={"Ime"}
              data={removeDuplicateValues(
                globalSelectedCustomerData.map((customer) => ({
                  label: customer.name,
                  value: customer.name,
                })),
              )}
              {...mergeForm.getInputProps("name")}
            />

            <Select
              w={"100%"}
              ta={"left"}
              label={"Priimek"}
              data={removeDuplicateValues(
                globalSelectedCustomerData.map((customer) => ({
                  label: customer.lastName,
                  value: customer.lastName,
                })),
              )}
              {...mergeForm.getInputProps("lastName")}
            />

            <Select
              w={"100%"}
              ta={"left"}
              label={"Telefonska številka:"}
              data={removeDuplicateValues(
                globalSelectedCustomerData
                  .filter((c) => c.gsm != null && c.gsm !== "")
                  .map((customer) => ({
                    label: customer.gsm,
                    value: customer.gsm?.toString() ?? "",
                  })),
              )}
              {...mergeForm.getInputProps("gsm")}
              allowDeselect={false}
            />

            <Select
              w={"100%"}
              ta={"left"}
              label={"E-pošta:"}
              data={removeDuplicateValues(
                globalSelectedCustomerData
                  .filter((c) => c.email != null && c.email !== "")
                  .map((customer) => ({
                    label: customer.email,
                    value: customer.email,
                  })),
              )}
              {...mergeForm.getInputProps("email")}
            />

            <Select
              w={"100%"}
              ta={"left"}
              label={"Komentar:"}
              data={removeDuplicateValues(
                globalSelectedCustomerData
                  .filter((c) => c.notes != null && c.notes !== "")
                  .map((customer) => ({
                    label: customer.notes,
                    value: customer.notes,
                  })),
              )}
              {...mergeForm.getInputProps("notes")}
            />

            <Flex justify={"flex-end"} gap={"md"}>
              <Button variant={"white"} onClick={closeMergeModal}>
                <Trans>Prekliči</Trans>
              </Button>
              <Button type="submit">
                <Trans>Združi</Trans>
              </Button>
            </Flex>
          </Flex>
        </form>
      </Modal>

      <LimeDrawer
        opened={modalOpened}
        onClose={modalOpened ? toggleModal : null}
        className="modal_customer"
        title={t`Dodaj stranko`}
        buttons={{
          save: (
            <Button type="submit">
              <Trans>Dodaj</Trans>
            </Button>
          ),
          cancel: (
            <Button variant="outline" onClick={toggleModal}>
              <Trans>Prekliči</Trans>
            </Button>
          ),
        }}
        form={{
          form,
          onFormSubmit: (values) => {
            submitCustomerForm(values);
            updateCustomers();
            toggleModal();
          },
        }}
      >
        <Flex direction={"column"} gap={20}>
          <TextInput
            id={"name"}
            label={t`Ime`}
            variant={"filled"}
            autocomplete="do-not-autofill"
            {...form.getInputProps("name")}
            required={true}
          />
          <TextInput
            id={"surname"}
            label={t`Priimek`}
            variant={"filled"}
            autocomplete="do-not-autofill"
            {...form.getInputProps("lastName")}
            required={true}
          />

          <TextInput
            id={"email"}
            label={t`E-pošta`}
            variant={"filled"}
            autocomplete="do-not-autofill"
            {...form.getInputProps("email")}
          />
          <div>
            <Text size={"sm"} fw={500} c={"#212529"}>
              <Trans>Telefonska številka</Trans>
            </Text>
            <LimePhoneInputField
              {...form.getInputProps("gsm")}
              id={"tel"}
              filled
              form
              autocomplete="do-not-autofill"
            />
          </div>

          <LimeLocalePicker {...form.getInputProps("language")} />

          <Textarea
            id={"comment"}
            label={t`Komentar`}
            variant={"filled"}
            autocomplete="do-not-autofill"
            {...form.getInputProps("notes")}
          />
        </Flex>
      </LimeDrawer>

      <PageHeader
        title={t`Pregled strank`}
        actionButton={{
          onClick: toggleModal,
          icon: <MdOutlineAdd size={"1.3rem"} />,
          label: t`Dodaj stranko`,
        }}
        subHeader={{
          tabs: [
            {
              label: t`Stranke`,
              value: "customers",
              rightSection: (
                <Flex align={"center"} gap={"sm"}>
                  <Text variant="caption" c="var(--secondary-text)">
                    <Trans>Razvrsti po:</Trans>
                  </Text>

                  <Select
                    data={[
                      { label: t`Imenu A-Z`, value: "nameAsc" },
                      { label: t`Imenu Z-A`, value: "nameDesc" },
                      { label: t`Priimku A-Z`, value: "lastNameAsc" },
                      { label: t`Priimku Z-A`, value: "lastNameDesc" },
                      {
                        label: t`Datumu zadnjega obiska (novejši najprej)`,
                        value: "lastVisitDesc",
                      },
                      {
                        label: t`Datumu zadnjega obiska (starejši najprej)`,
                        value: "lastVisitAsc",
                      },
                    ]}
                    value={sortBy}
                    onChange={(value) => setSortBy(value)}
                  />
                </Flex>
              ),
            },
          ],
        }}
      />

      <ActionsRow>
        <ActionsIcons>
          <Flex>
            <button
              onClick={() => {
                if (
                  customers.length > 0 &&
                  customers.every((customer) =>
                    globalSelectedCustomerData.some(
                      (selectedCustomer) =>
                        selectedCustomer.customerId === customer.customerId,
                    ),
                  )
                ) {
                  setGlobalSelectedCustomerData([]);
                } else {
                  setGlobalSelectedCustomerData(customers);
                }
              }}
              className={"action-button checkbox select-all"}
              style={{ background: "none", border: "none" }}
            >
              <input
                checked={globalSelectedCustomerData.length == customers.length}
                type={"checkbox"}
              />
              <span
                style={{
                  color: "#6C7B86",
                  fontSize: "0.8rem",
                  marginLeft: "0.5rem",
                  cursor: "pointer",
                  marginRight: "0.5rem",
                  userSelect: "none",
                }}
              >
                <Trans>Označi vse</Trans> ({globalSelectedCustomerData.length})
              </span>
            </button>

            <Actions active={true} activeFull={true}>
              <div className={"active-buttons-transition-wrapper"}>
                {globalSelectedCustomerData.length > 0 &&
                  globalSelectedCustomerData.length <= 6 && (
                    <button
                      style={{ border: "none" }}
                      onClick={() => {
                        mergeForm.reset();
                        const firstSelected = globalSelectedCustomerData[0];
                        mergeForm.setValues({
                          name: firstSelected.name,
                          lastName: firstSelected.lastName,
                          gsm: firstSelected.gsm,
                          email: firstSelected.email,
                          notes: firstSelected.notes,
                        });
                        openMergeModal();
                      }}
                      className={"merge-action action-button"}
                    >
                      <RiGitMergeLine />
                      {!isMobile && (
                        <span>
                          <Trans>Združi izbrane</Trans>
                        </span>
                      )}
                    </button>
                  )}

                {isFeatureEnabled("BULK_NOTIF") &&
                  globalSelectedCustomerData.length > 0 && (
                    <button
                      style={{ border: "none" }}
                      onClick={openBulkSMSModal}
                      className={"send-sms action-button"}
                    >
                      <RiMailSendLine />
                      {!isMobile && (
                        <span>
                          <Trans>Pošlji SMS</Trans>
                        </span>
                      )}
                    </button>
                  )}
              </div>
            </Actions>
          </Flex>
          <TextInput
            placeholder={t`Iskanje`}
            defaultValue={searchQuery}
            leftSection={<RiSearch2Line style={{ color: "#6C7B86" }} />}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </ActionsIcons>
      </ActionsRow>

      {globalSelectedCustomerData.length > 0 && (
        <Flex
          direction={isMobile ? "column" : "row"}
          justify={isMobile ? "center" : "space-between"}
          align={isMobile ? "center" : "flex-start"}
          p={10}
          my={10}
          style={{
            width: "100%",
            border: "1px solid #eaebed",
            borderRadius: "10px",
          }}
        >
          <Flex
            direction={isMobile ? "column" : "row"}
            align="center"
            wrap={"wrap"}
            gap={5}
            mb={isMobile ? "10px" : 0}
          >
            <Text size="sm" weight={500} mr={isMobile ? 0 : "1rem"}>
              <Trans>Izbrane stranke:</Trans>
            </Text>
            {globalSelectedCustomerData.map((customer) => (
              <Badge
                key={customer.customerId}
                color="green"
                variant="light"
                ml="5px"
              >
                {customer.name} {customer.lastName}
              </Badge>
            ))}
          </Flex>

          <Button
            mt={isMobile ? "10px" : 0}
            style={
              isMobile ? { alignSelf: "center" } : { alignSelf: "flex-end" }
            }
            miw={"75px"}
            variant="outline"
            onClick={() => setGlobalSelectedCustomerData([])}
          >
            <Trans>Razveljavi</Trans>
          </Button>
        </Flex>
      )}

      {!isLoading && customers.length === 0 && (
        <EmptyState
          title={t`Nimate še strank`}
          icon={<RiGroupFill fill={"#6c7b86"} size={"2rem"} />}
          description={t`Dodajte prvi termin, da bo na voljo pregled strank.`}
        />
      )}

      <PageContentTable
        toggleSelectedObject={toggleSelectedCustomer}
        isMobile={isMobile}
        objects={customers}
        selectRow={(customer) => selectRow(customer.customerId)}
        globalSelectedCustomers={globalSelectedCustomerData}
        idValue={"customerId"}
        isLoading={isLoading}
        perPage={perPage}
        values={customers.map((c) => [
          { label: t`Ime`, value: c.name },
          { label: t`Priimek`, value: c.lastName },
          { label: t`E-poštni naslov`, value: c.email },
          {
            label: t`Telefonska številka`,
            value: c.gsm,
            icons: [
              c.gsm &&
                c.formBlocked && {
                  icon: <MdBlock />,
                  tooltip: t`Telefonska številka blokirana na spletnem naročanju`,
                },
            ],
          },
          {
            label: t`Datum zadnjega obiska`,
            value: c.startTime
              ? dayjs(c.startTime).utc(false).format("DD. MM. YYYY HH:mm")
              : t`Še ni obiska`,
          },
        ])}
      />

      <Space h={"1rem"} />

      <LimePagination
        label={t`strank`}
        page={paginationPage}
        pageCount={pagination.pageCount}
        perPage={perPage}
        setPage={setPaginationPage}
        setPerPage={setPerPage}
      />

      <LimeModal
        classNames={{
          body: "px-3",
        }}
        isOpen={Boolean(selectedCustomerId)}
        onOpenChange={(open) => {
          if (!open) setSelectedCustomerId(null);
        }}
        title={t`Kartica stranke`}
        size="full"
        isDismissable={false}
      >
        <ModalContent>
          <ModalHeader className="flex flex-col gap-1">
            <Trans>Kartica stranke</Trans>
          </ModalHeader>
          <ModalBody>
            <CustomerCard
              customerId={selectedCustomerId}
              handleClose={() => {
                setSelectedCustomerId(null);
                updateCustomers();
              }}
            />
          </ModalBody>
        </ModalContent>
      </LimeModal>

      <BulkSMSModal
        onToggle={(value) => (value ? openBulkSMSModal() : closeBulkSMSModal())}
        opened={bulkSMSModalOpened}
        selectedCustomerIds={globalSelectedCustomerData.map(
          (c) => c.customerId,
        )}
      />
    </>
  );
};

export default Completed;
