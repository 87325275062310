import { t } from "@lingui/core/macro";
import { Trans } from "@lingui/react/macro";
import { AlertChip } from "@/Components/AlertChip/AlertChip";
import { LimeLoader } from "@/Components/LimeLoader";
import { api } from "@/lib/api-client";
import { UpdateGlobalPaymentSettings } from "@/server-types";
import { TextVariant } from "@/types/text-variants";
import {
  Box,
  Button,
  Divider,
  Flex,
  NumberInput,
  SegmentedControl,
  Text,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useMediaQuery } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { useEffect, useState } from "react";
import { HiOutlineExternalLink } from "react-icons/hi";
import { useNavigate } from "react-router";
import { OptionsList } from "./Components/OptionsList";
import { StripePageHeader } from "./Components/StripePageHeader";
import { returnCurrencySymbol } from "../../../../../shared/utils/utils";
import { LimeSwitch } from "@/Components/LimeSwitch";

export const AccountSettings = () => {
  const isRedirectedFromCompletedOnboarding =
    window.location.href.includes("onboarding=1");

  const navigate = useNavigate();

  const {
    data: accountData,
    isPending: isPendingAccountData,
    isSuccess: isSuccessAccountData,
    refetch: refetchAccountData,
  } = api.stripe.useGetStripeAccount();

  const {
    isLoading: isLoadingOnboardingData,
    isRefetching,
    refetch: continueOnBoarding,
  } = api.stripe.useGetStripeAccountOnboardingUrl();
  const isLoadingOnboarding = isLoadingOnboardingData || isRefetching;

  const {
    mutateAsync: updateGlobalPaymentSetting,
    isPending: isUpdatingGlobalPaymentSetting,
  } = api.stripe.useUpdateStripePaymentSettingsGlobal();

  const { data: paymentSettings } = api.stripe.useGetStripePaymentSettings();

  const [afterOnboardingTimerActive, setAfterOnboardingTimerActive] =
    useState<boolean>(false);
  useEffect(() => {
    if (!isRedirectedFromCompletedOnboarding) return;

    const interval = setInterval(() => {
      console.log("end interval");
      refetchAccountData();

      setAfterOnboardingTimerActive(false);
    }, 10000);

    // setActiveTab("settings");
    setAfterOnboardingTimerActive(true);

    return () => clearInterval(interval);
  }, []);

  const globalPaymentForm = useForm({
    initialValues: {
      globalPaymentPriceAmount: 0,
      globalPaymentPriceType: "percentage",
      isPaymentDefaultCheckedInDashboard: false,
      stripePaymentTimeout: 0,
      requirePaymentForNewCustomers: true,
      requirePaymentForExistingCustomers: true,
    } as UpdateGlobalPaymentSettings["body"]["updateData"],
    validate: {
      globalPaymentPriceAmount: (value) => {
        if (value < 0) {
          return t`Cena mora biti večja od 0`;
        }

        if (
          globalPaymentForm.values.globalPaymentPriceType === "percentage" &&
          value > 100
        ) {
          return t`Cena ne sme presegati 100%`;
        }
      },
    },
  });

  useEffect(() => {
    if (!paymentSettings) {
      return;
    }

    const data: (typeof globalPaymentForm)["values"] = {
      globalPaymentPriceAmount: paymentSettings.global.paymentPriceAmount ?? 0,
      globalPaymentPriceType:
        paymentSettings.global.paymentPriceType ?? "percentage",
      isPaymentDefaultCheckedInDashboard:
        paymentSettings.isPaymentDefaultCheckedInDashboard ?? true,
      stripePaymentTimeout: paymentSettings.global.stripePaymentTimeout ?? 1,
      requirePaymentForNewCustomers:
        paymentSettings.global.requirePaymentForNewCustomers ?? true,
      requirePaymentForExistingCustomers:
        paymentSettings.global.requirePaymentForExistingCustomers ?? true,
    };

    globalPaymentForm.setValues(data);
    globalPaymentForm.resetDirty(data);
  }, [paymentSettings]);

  const onContinueOnBoarding = () => {
    continueOnBoarding().then((res) => {
      if (res.data == null) {
        return;
      }

      window.location.href = res.data;
    });
  };

  const submitForm = async () => {
    /**
     * Only open dialog to update service settings if user changed
     * global amount or type
     */
    if (
      globalPaymentForm.isDirty("globalPaymentPriceAmount") ||
      globalPaymentForm.isDirty("globalPaymentPriceType")
    ) {
      modals.openConfirmModal({
        title: t`Želite posodobiti vrednosti tudi za nastavitve po meri?`,
        labels: {
          confirm: t`Da`,
          cancel: t`Ne`,
        },
        onCancel: async () => {
          await finishSubmittingForm({ updateCustomServices: false });
        },
        onConfirm: async () => {
          await finishSubmittingForm({ updateCustomServices: true });
        },
      });
    } else {
      await finishSubmittingForm({ updateCustomServices: false });
    }
  };

  const finishSubmittingForm = async ({
    updateCustomServices,
  }: {
    updateCustomServices: boolean;
  }) => {
    await updateGlobalPaymentSetting({
      updateData: globalPaymentForm.values,
      updateCustomServices,
    });

    refetchAccountData();
  };

  const isMobile = useMediaQuery("(max-width: 768px)");

  if (isPendingAccountData) {
    return <LimeLoader />;
  }

  if (!isSuccessAccountData) {
    return (
      <Text>
        <Trans>
          Nekaj je šlo narobe pri pridobivanju podatkov o Stripe računu
        </Trans>
      </Text>
    );
  }

  return (
    <Flex
      align={"flex-start"}
      justify={"flex-start"}
      direction={"column"}
      mih={"calc(95dvh - 136px)"}
      w={"100%"}
    >
      <>
        <Text variant={TextVariant.BodyEmphasized}>
          <Trans>Podatki o Stripe računu</Trans>
        </Text>
        <Box my="xs">
          <Text
            style={{
              lineHeight: 1.25,
            }}
          >
            <Text
              style={{
                display: "inline-block",
                width: "50px",
              }}
              variant={TextVariant.Caption}
            >
              <Trans>ID št.</Trans>:
            </Text>{" "}
            <Text c="var(--secondary-text)" span variant={TextVariant.Caption}>
              {accountData.stripeAccountId}
            </Text>
          </Text>
          <Text>
            <Text
              style={{
                display: "inline-block",
                width: "50px",
              }}
              variant={TextVariant.Caption}
            >
              <Trans>E-mail</Trans>:
            </Text>{" "}
            <Text c="var(--secondary-text)" span variant={TextVariant.Caption}>
              {accountData?.email}
            </Text>
          </Text>
        </Box>

        <AlertChip
          text={
            accountData.hasFinishedOnboarding
              ? t`Račun uspešno povezan`
              : t`Račun ni povezan`
          }
          variant={accountData.hasFinishedOnboarding ? "success" : "error"}
        />

        <Divider mt="lg" mb={"md"} w="100%" />

        <form
          onSubmit={globalPaymentForm.onSubmit(submitForm)}
          style={{ display: "contents" }}
        >
          <StripePageHeader
            title={t`Globalne nastavitve`}
            subtitle={t`Določite znesek, ki ga morajo stranke plačati za potrditev termina. V primeru, da je predplačilo v evrih in presega ceno storitve, stranka plača 100% avans.`}
          />

          <Flex
            justify={isMobile ? "space-between" : "flex-start"}
            gap={"lg"}
            w="100%"
            align={"flex-end"}
          >
            <NumberInput
              label={t`Izberite odstotke ali znesek`}
              style={{ flex: 1 }}
              {...globalPaymentForm.getInputProps("globalPaymentPriceAmount")}
              hideControls={true}
              min={0}
              placeholder="0"
              max={
                globalPaymentForm.values.globalPaymentPriceType === "percentage"
                  ? 100
                  : undefined
              }
              maw={isMobile ? "100%" : "160px"}
              styles={{
                label: {
                  color: "#8C8C8C",
                  fontSize: "0.75rem",
                  fontWeight: 400,
                },
              }}
            />
            <SegmentedControl
              data={[
                { value: "percentage", label: "%" },
                {
                  value: "amount",
                  label: returnCurrencySymbol({
                    currency: paymentSettings?.mainCurrency ?? "EUR",
                  }),
                },
              ]}
              {...globalPaymentForm.getInputProps("globalPaymentPriceType")}
              w={"120px"}
              styles={{
                root: {
                  height: "36px",
                },
              }}
            />
          </Flex>

          <Divider my={"lg"} w="100%" />

          <StripePageHeader
            title={t`Privzeti čas za plačilo (min)`}
            subtitle={t`Koliko časa ima stranka, da plača termin, preden plačilo poteče. Privzeta nastavitev, če ni določena na samem terminu.`}
          />

          <NumberInput
            {...globalPaymentForm.getInputProps("stripePaymentTimeout")}
            min={1}
          />

          <div className="mt-4 flex w-full flex-col gap-4">
            <LimeSwitch
              width={"100%"}
              label={t`Zahtevaj plačilo za nove stranke`}
              {...globalPaymentForm.getInputProps(
                "requirePaymentForNewCustomers",
                { type: "checkbox" },
              )}
            />

            <LimeSwitch
              width={"100%"}
              label={t`Zahtevaj plačilo za obstoječe stranke`}
              {...globalPaymentForm.getInputProps(
                "requirePaymentForExistingCustomers",
                { type: "checkbox" },
              )}
            />
          </div>

          <Divider mt={"lg"} w="100%" />

          <OptionsList
            options={[
              {
                label: t`Nastavitve po meri - storitve`,
                description: t`Določite znesek za vsako storitev po meri.`,
                onClick: () => {
                  navigate("services", { relative: "path" });
                },
                disabled:
                  !accountData?.hasFinishedOnboarding || isLoadingOnboarding,
              },
            ]}
          />

          <div className="w-full">
            {" "}
            <LimeSwitch
              width={"100%"}
              label={t`Ali je potrebno plačilo ob dodajanju termina iz koledarja?`}
              {...globalPaymentForm.getInputProps(
                "isPaymentDefaultCheckedInDashboard",
                { type: "checkbox" },
              )}
            ></LimeSwitch>
          </div>

          <div style={{ flex: 1 }}></div>

          <Button
            type="submit"
            w={isMobile ? "100%" : "100px"}
            fw={500}
            loading={isUpdatingGlobalPaymentSetting}
            ml={"auto"}
            disabled={!globalPaymentForm.isDirty()}
          >
            <Trans>Shrani</Trans>
          </Button>
        </form>

        <div style={{ flex: 1 }}></div>

        {(afterOnboardingTimerActive || !accountData.hasFinishedOnboarding) && (
          <>
            <Flex
              style={{
                borderRadius: "4px",
                border: "1px solid #D8D8D8",
              }}
              p={"1rem"}
              bg={"#F5F5F5"}
              mb={"xs"}
              w={"100%"}
              justify={"space-between"}
              align={"center"}
            >
              <div>
                <Text fw={500} size={".875rem"} c={"#262626"}>
                  {afterOnboardingTimerActive ? (
                    <Trans>Stripe račun se povezuje</Trans>
                  ) : (
                    <Trans>Stripe račun ni povezan</Trans>
                  )}
                </Text>
                <Text fw={400} size={".875rem"} c={"#8C8C8C"}>
                  {afterOnboardingTimerActive ? (
                    <Trans>
                      Povezava s Stripe računom se vzpostavlja. Prosimo
                      počakajte trenutek.
                    </Trans>
                  ) : (
                    <Trans>
                      Povezavo s Stripe računom niste dokončali. Za nadaljevanje
                      vzpostavitve spletnega plačevanja kliknite spodaj.
                    </Trans>
                  )}
                </Text>
              </div>

              {!isMobile && (
                <Button
                  onClick={onContinueOnBoarding}
                  fw={500}
                  rightSection={<HiOutlineExternalLink />}
                  loading={isLoadingOnboarding || afterOnboardingTimerActive}
                  mr={"xs"}
                >
                  <Trans>Končaj povezavo</Trans>
                </Button>
              )}
            </Flex>
            {isMobile && (
              <Button
                onClick={onContinueOnBoarding}
                w="100%"
                fw={500}
                rightSection={<HiOutlineExternalLink />}
                loading={isLoadingOnboarding || afterOnboardingTimerActive}
              >
                <Trans>Končaj povezavo</Trans>
              </Button>
            )}
          </>
        )}
      </>
    </Flex>
  );
};
