import { Button, Modal, ModalProps } from "@heroui/react";
import { X } from "lucide-react";
import React from "react";

export const LimeModal = ({
  children,
  ...props
}: { children: React.ReactNode } & ModalProps) => {
  return (
    <Modal
      scrollBehavior={props.scrollBehavior ? props.scrollBehavior : "outside"}
      closeButton={
        props.closeButton ? (
          props.closeButton
        ) : (
          <Button isIconOnly variant="light">
            <X size={20} />
          </Button>
        )
      }
      {...props}
    >
      {children}
    </Modal>
  );
};
