import { t } from "@lingui/core/macro";
import { useConfirmCustomer2FA } from "@/lib/api-client/paths/customer";
import { Divider, Loader, Text } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { notifications } from "@mantine/notifications";
import dayjs from "dayjs";
import { useEffect } from "react";
import {
  emailValid,
  fullPhoneNumberToNumberAndCountryCode,
} from "../../../../Utilities";
import { apiUpdateCustomer } from "../../../../apiCalls";
import { useCustomers } from "../../../../hooks/useCustomers";
import { useFormFields } from "../../../../hooks/useFormFields";
import useUserPermissionsStore from "../../../../stores/useUserPermissionsStore";
import Analytics from "./Analytics/Analytics";
import CustomerAppointments from "./CustomerAppointmentsList/CustomerAppointments";
import EditingCustomerForm from "./EditingCustomerForm";
import { returnCurrencySymbol } from "../../../../../../shared/utils/utils";
import { api } from "@/lib/api-client";
import { addToast } from "@heroui/react";
import { Tab, Tabs } from "@heroui/react";
import { CustomerFiles } from "./CustomerFiles";

const CustomerCard = ({ customerId, handleClose }) => {
  const isMobile = useMediaQuery("(max-width: 768px)");

  const { deleteCustomer, setCustomerData, customerData, refreshCustomerData } =
    useCustomers();

  const { mutateAsync: confirmCustomer2FA } = useConfirmCustomer2FA();

  const [fields, setValues, _, setFields] = useFormFields({});

  const permissions = useUserPermissionsStore((state) => state.permissions);

  const { data: currency } = api.values.useGetCurrency({});

  const currencySymbol = returnCurrencySymbol({ currency: currency || "EUR" });

  useEffect(() => {
    async function fetchCustomerData() {
      refreshCustomerData(customerId);
    }
    if (customerId) {
      fetchCustomerData();
    }
  }, [customerId]);

  const deleteCustomerHandler = async () => {
    await deleteCustomer(customerData.customerId);
    handleClose({ isCustomerDeleted: true });
  };

  const submitCustomerFormHandler = async () => {
    const token = localStorage.getItem("usr_token");
    const user = { ...fields };

    if (user.email && !emailValid(user.email)) {
      addToast({
        title: t`Nepravilen elektronski naslov`,
        description: t`Elektronski naslov ni pravilen.`,
        color: "danger",
      });
      return;
    }

    const phoneNumber = fullPhoneNumberToNumberAndCountryCode(user.gsm);
    if (phoneNumber != null) {
      user.gsm = phoneNumber.gsm;
      user.countryCode = phoneNumber.countryCode;
    } else {
      user.gsm = null;
      user.countryCode = customerData.countryCode;
    }

    const { shouldOverridePaymentPercentage } = user;
    delete user.shouldOverridePaymentPercentage;

    const cData = { ...customerData };
    delete cData.upcommingAppointments;
    delete cData.completedAppointments;

    const response = await apiUpdateCustomer(
      {
        ...cData,
        ...user,
        birthday: user.birthday
          ? dayjs(user.birthday).format("YYYY-MM-DD")
          : null,
        customerId: customerData.customerId,
        notes: user.notes ?? "",
        language: user.language,
        formPaymentRequirement: user.formPaymentRequirement,
        formPaymentPercentageOverride:
          shouldOverridePaymentPercentage &&
          user.formPaymentRequirement === "always"
            ? user.formPaymentPercentageOverride
            : null,
      },
      token,
    );

    if (response.status === 200) {
      setCustomerData(() => ({
        ...customerData,
        ...user,
        customerId: customerData.customerId,
      }));
    }
  };

  const openDeleteConfirmModal = () => {
    modals.openConfirmModal({
      title: t`Izbriši osebo`,
      children: (
        <Text size="sm">
          Oseba bo izbrisana. Termini te osebe bodo ostali v analitiki. Želite
          nadaljevati?
        </Text>
      ),
      labels: { confirm: t`Potrdi`, cancel: t`Prekliči` },
      onConfirm: deleteCustomerHandler,
      withinPortal: true,
      zIndex: 10000,
      centered: true,
    });
  };

  const handleConfirmGsmEmailClick = async (type) => {
    modals.openConfirmModal({
      title: t`Ali ste prepričani, da želite potrditi ${type} za 2FA sistem?`,
      labels: { confirm: t`Potrdi`, cancel: t`Prekliči` },
      onConfirm: async () => {
        const response = await confirmCustomer2FA({
          customerId: customerData.customerId,
          body: {
            confirmEmail: type === "email",
            confirmGsm: type === "gsm",
          },
        });
        if (response.message === "OK") {
          notifications.show({
            title: t`Uspešno potrjeno`,
            message:
              type === "gsm"
                ? t`Telefonska številka je bila uspešno potrjena.`
                : t`Elektronski naslov je bil uspešno potrjen.`,
          });
        }
        refreshCustomerData(customerId);
      },
      withinPortal: true,
      zIndex: 10000,
      centered: true,
    });
  };

  if (!customerData) {
    return <Loader />;
  }

  return (
    <div className="flex flex-col justify-between md:flex-row">
      <div id="left-side" className="w-full md:w-[70%]">
        <Tabs disableAnimation={true} variant="underlined">
          <Tab key={"info"} title={t`Oseba`}>
            <EditingCustomerForm
              submitCustomerFormHandler={submitCustomerFormHandler}
              fields={fields}
              setFields={setFields}
              setValues={setValues}
              isMobile={isMobile}
              customerData={customerData}
              openConfirmDeleteModal={openDeleteConfirmModal}
              openConfirmGsmEmailClick={handleConfirmGsmEmailClick}
            />
            {permissions.view_analytics && (
              <Analytics
                currencySymbol={currencySymbol}
                completedAppointments={customerData.completedAppointments || []}
                isMobile={isMobile}
                currency={customerData.mainCurrency ?? "EUR"}
              />
            )}
          </Tab>
          <Tab key={"files"} title={t`Datoteke`}>
            <CustomerFiles customerId={customerId} />
          </Tab>
        </Tabs>
      </div>
      <Divider orientation={"vertical"} mx={"md"} />
      <CustomerAppointments
        currencySymbol={currencySymbol}
        refreshCustomerData={refreshCustomerData}
        setCustomerData={setCustomerData}
        customerData={customerData}
        isMobile={isMobile}
      />
    </div>
  );
};

export const IconsContainer = ({ children }) => (
  <div className="flex items-center gap-4">{children}</div>
);

export const Details = ({ children }) => (
  <div className="mb-4 flex flex-col gap-2">{children}</div>
);

export default CustomerCard;
