import { t } from "@lingui/core/macro";
import { Trans } from "@lingui/react/macro";
import { useEffect, useState, useMemo } from "react";
import { isValidPhoneNumber } from "react-phone-number-input";
import { LimePhoneInput } from "@/Components/NextBase/LimePhoneInput";
import {
  emailValid,
  numberAndCountryCodeToFullPhoneNumber,
} from "../../../../Utilities";
import { LimeLocalePicker } from "@/Components/NextBase/LimeLocalePicker";
import { MdCopyAll, MdPhone, MdEmail } from "react-icons/md";
import { notifications } from "@mantine/notifications";
import {
  Button,
  Input,
  Textarea,
  Checkbox,
  DatePicker,
  Accordion,
  AccordionItem,
  Divider,
} from "@heroui/react";
import { ChevronDown } from "lucide-react";
import { FaCheckCircle } from "react-icons/fa";
import { parseDate } from "@internationalized/date";
import dayjs from "dayjs";
import useAccountInfo from "@/hooks/useAccountInfo";
import useFeature from "@/hooks/useFeature";
import { LimeSelect } from "@/Components/NextBase/LimeSelect";
import { LimeCollapse } from "@/Components/NextBase/LimeCollapse";
import { LimeSwitch } from "@/Components/NextBase/LimeSwitch";
import { LimeStepperNumberInput } from "@/Components/NextBase/LimeStepperNumberInput";
import { LimeDatePicker } from "@/Components/NextBase/LimeDatePicker";

const EditingCustomerForm = ({
  customerData,
  fields,
  setFields,
  setValues,
  submitCustomerFormHandler,
  openConfirmDeleteModal,
  openConfirmGsmEmailClick,
}) => {
  const [initialFields, setInitialFields] = useState(null);
  const { isFeatureEnabled } = useFeature();

  const { accountInfo } = useAccountInfo();

  useEffect(() => {
    const newFields = {
      name: customerData.name,
      lastName: customerData.lastName,
      email:
        customerData.email && emailValid(customerData.email)
          ? customerData.email
          : "",
      gsm:
        customerData.gsm &&
        isValidPhoneNumber(
          numberAndCountryCodeToFullPhoneNumber(
            customerData.gsm,
            customerData.countryCode,
          ),
        )
          ? numberAndCountryCodeToFullPhoneNumber(
              customerData.gsm,
              customerData.countryCode,
            )
          : "",
      notes: customerData.notes,
      formBlocked: customerData.formBlocked,
      language: customerData.language || "en",
      birthday: customerData.birthday
        ? parseDate(dayjs(customerData.birthday).format("YYYY-MM-DD"))
        : null,
      formPaymentRequirement: customerData.formPaymentRequirement,
      shouldOverridePaymentPercentage:
        customerData.formPaymentRequirement === "always" &&
        customerData.formPaymentPercentageOverride,
      formPaymentPercentageOverride:
        customerData.formPaymentPercentageOverride ?? 0,
    };
    setFields(newFields);
    setInitialFields(newFields);
  }, [customerData, setFields]);

  const hasChanged = useMemo(() => {
    if (!initialFields) return false;
    return JSON.stringify(fields) !== JSON.stringify(initialFields);
  }, [fields, initialFields]);

  const customerSummary = (
    <div className="flex flex-col gap-0">
      <div className="flex items-center gap-2">
        <span className="font-medium">
          {customerData.name} {customerData.lastName}
        </span>
        {customerData.formBlocked && (
          <span className="flex gap-1 text-xs text-danger-500">
            {"("}
            <Trans>Blokiran</Trans>
            {")"}
          </span>
        )}
      </div>
      <div className="flex items-center gap-2 text-sm text-default-500">
        <a
          href={`mailto:${customerData.email}`}
          className="transition-colors hover:text-primary-500"
        >
          {customerData.email}
        </a>
        {customerData.isEmailConfirmed && (
          <FaCheckCircle className="text-green-500" />
        )}
      </div>
      <div className="flex items-center gap-2 text-sm text-default-500">
        <a
          href={`tel:${customerData.gsm}`}
          className="transition-colors hover:text-primary-500"
        >
          {customerData.gsm}
        </a>
        {customerData.isGsmConfirmed && (
          <FaCheckCircle className="text-green-500" />
        )}
      </div>
      {customerData.notes && (
        <div className="mt-1 text-sm text-default-500">
          {customerData.notes}
        </div>
      )}
    </div>
  );

  const editForm = (
    <div className="flex flex-col gap-4">
      <Input
        size="sm"
        value={fields.name}
        onChange={(e) => setValues(e.target.value, "name")}
        label={t`Ime`}
      />

      <Input
        size="sm"
        value={fields.lastName}
        onChange={(e) => setValues(e.target.value, "lastName")}
        label={t`Priimek`}
      />

      <div>
        <div className="flex items-center gap-2">
          <Input
            size="sm"
            value={fields.email}
            onChange={(e) => setValues(e.target.value, "email")}
            label={t`Email`}
          />
          <a
            href={`mailto:${fields.email}`}
            className="cursor-pointer text-default-400 hover:text-primary-500"
          >
            <MdEmail size={20} />
          </a>
        </div>
      </div>

      <div className="flex items-center gap-2">
        <div className="flex-1">
          <LimePhoneInput
            variant="flat"
            value={fields.gsm}
            onChange={(e) => setValues(e, "gsm")}
          />
        </div>
        <a
          href={`tel:${fields.gsm}`}
          className="cursor-pointer text-default-400 hover:text-primary-500"
        >
          <MdPhone size={20} />
        </a>
      </div>
      {isFeatureEnabled("2FA") && (
        <div className="flex flex-col gap-2">
          {accountInfo.twoFactorAuthChannel === "sms" && customerData.gsm && (
            <>
              {customerData.isGsmConfirmed ? null : (
                <Button
                  variant="bordered"
                  onPress={() => openConfirmGsmEmailClick("gsm")}
                >
                  <Trans>(2FA) Potrdi telefonsko številko</Trans>
                </Button>
              )}
            </>
          )}
          {accountInfo.twoFactorAuthChannel === "email" &&
            customerData.email && (
              <>
                {customerData.isEmailConfirmed ? null : (
                  <Button
                    variant="bordered"
                    onPress={() => openConfirmGsmEmailClick("email")}
                  >
                    <Trans>(2FA) Potrdi elektronski naslov</Trans>
                  </Button>
                )}
              </>
            )}
        </div>
      )}

      <LimeDatePicker
        size="sm"
        value={fields.birthday}
        onChange={(e) => setValues(e, "birthday")}
        label={t`Datum rojstva`}
        showMonthAndYearPickers
        defaultValue={null}
        placeholder="Select date"
      />

      <LimeLocalePicker
        size="sm"
        value={fields.language}
        onChange={(value) => setValues(value, "language")}
      />

      <Checkbox
        size="sm"
        isSelected={fields.formBlocked}
        onValueChange={(value) => setValues(value, "formBlocked")}
      >
        <Trans>Blokiraj stranko za spletno naročanje</Trans>
      </Checkbox>

      <Divider className="my-2" />

      <LimeSelect
        label={t`Zahtevaj plačilo preko spletne forme`}
        items={[
          { label: t`Privzeto`, key: "default" },
          { label: t`Nikoli`, key: "never" },
          { label: t`Vedno`, key: "always" },
        ]}
        value={fields["formPaymentRequirement"]}
        onSingleItemChange={(value) => {
          setValues(value, "formPaymentRequirement");
        }}
      />
      <LimeCollapse isOpen={fields["formPaymentRequirement"] === "always"}>
        <LimeSwitch
          title={t`Določi plačilo po meri`}
          checked={fields["shouldOverridePaymentPercentage"]}
          onChange={(value) => {
            setValues(value, "shouldOverridePaymentPercentage");
          }}
          size="sm"
        />
      </LimeCollapse>
      <LimeCollapse isOpen={fields["shouldOverridePaymentPercentage"]}>
        <LimeStepperNumberInput
          label={t`Zahtevaj plačilo v višini`}
          value={fields["formPaymentPercentageOverride"]}
          min={0}
          max={100}
          step={1}
          onValueChange={(value) => {
            if (value > 100) {
              setValues(100, "formPaymentPercentageOverride");
            } else if (value < 0) {
              setValues(0, "formPaymentPercentageOverride");
            } else {
              setValues(value, "formPaymentPercentageOverride");
            }
          }}
          endContent={"%"}
        />
      </LimeCollapse>

      <div className="flex items-center gap-2 text-sm text-default-500 hover:cursor-pointer hover:text-green-600">
        <Button
          variant="bordered"
          className="cursor-pointer text-primary-500 hover:text-primary-600"
          onPress={() => {
            const rescheduleLink = `${import.meta.env.VITE_FORM_URL}/customer/${customerData.customerHash}`;
            navigator.clipboard.writeText(rescheduleLink);
            notifications.show({
              title: t`Povezava je bila kopirana!`,
              message: t`Povezavo lahko prilepite v sporočilo stranki.`,
            });
          }}
        >
          <MdCopyAll /> <Trans>Povezava za prenaročanje</Trans>
        </Button>
      </div>

      <Textarea
        size="sm"
        value={fields.notes}
        onChange={(e) => setValues(e.target.value, "notes")}
        label={t`Komentar`}
      />

      {hasChanged && (
        <div className="flex w-full gap-2">
          <Button
            className="w-1/2"
            onPress={submitCustomerFormHandler}
            color="primary"
          >
            <Trans>Shrani</Trans>
          </Button>
          <Button
            className="w-1/2"
            onPress={() => {
              if (initialFields) {
                setFields(initialFields);
              }
            }}
          >
            <Trans>Prekliči</Trans>
          </Button>
        </div>
      )}

      <Button color="danger" variant="flat" onPress={openConfirmDeleteModal}>
        <Trans>Izbriši stranko</Trans>
      </Button>
    </div>
  );

  return (
    <div className="mx-2 rounded-md border border-default-200 bg-default-50">
      <Accordion>
        <AccordionItem
          key="customer"
          title={customerSummary}
          indicator={<ChevronDown />}
        >
          {editForm}
        </AccordionItem>
      </Accordion>
    </div>
  );
};

export default EditingCustomerForm;
