import { LimeAlert } from "@/Components/NextBase/LimeAlert";
import { api } from "@/lib/api-client";
import {
  addToast,
  Button,
  Card,
  CardBody,
  Divider,
  Tooltip,
  Progress,
  cn,
  Skeleton,
} from "@heroui/react";
import { t } from "@lingui/core/macro";
import { Plural, Trans } from "@lingui/react/macro";
import { CloudUpload, Eye, Trash, Download } from "lucide-react";
import { filesize } from "filesize";
import { LimeInput } from "@/Components/NextBase/LimeInput";
import { AlertButton } from "@/Components/NextBase/AlertButton";
import { useState } from "react";
import { useForm } from "@mantine/form";
import { LimePagination } from "@/Components/NextBase/LimePagination";
import { useSearchParams } from "react-router";

// because we get a CORS error when using axios for uploading files
function uploadFileWithXHR(
  file: File,
  presignedUrl: string,
  onProgress: (percent: number) => void,
) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();

    // Track progress
    xhr.upload.addEventListener("progress", (event) => {
      if (event.lengthComputable) {
        const percent = Math.round((event.loaded / event.total) * 100);
        onProgress?.(percent);
      }
    });

    // Success/error listeners
    xhr.addEventListener("load", () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        resolve(xhr.response);
      } else {
        reject(new Error(`Upload failed with status ${xhr.status}`));
      }
    });
    xhr.addEventListener("error", reject);

    // Set up request
    xhr.open("PUT", presignedUrl);
    xhr.setRequestHeader("Content-Type", file.type);
    xhr.send(file);
  });
}

export const CustomerFiles = ({ customerId }: { customerId: number }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.has("page")
    ? parseInt(searchParams.get("page")!)
    : 1;
  const perPage = searchParams.has("perPage")
    ? searchParams.get("perPage")!
    : "10";

  const setPage = (page: number) => {
    setSearchParams((prev) => {
      prev.set("page", page.toString());
      return prev;
    });
  };

  const setPerPage = (perPage: string) => {
    setSearchParams((prev) => {
      prev.set("perPage", perPage);
      prev.set("page", "1");
      return prev;
    });
  };

  const {
    data,
    isFetching,
    refetch,
    processedErrorMessage: fetchError,
  } = api.storage.useGetCustomerFiles(customerId, {
    page,
    limit: parseInt(perPage),
  });

  const {
    mutateAsync: getCustomerFileUploadPresignedUrl,
    isPending: isGettingPresignedUrl,
    processedErrorMessage: getPresignedUrlError,
  } = api.storage.useGetCustomerFileUploadPresignedUrl();

  const {
    mutateAsync: completeCustomerFileUpload,
    isPending: isCompletingCustomerFileUpload,
    processedErrorMessage: completeCustomerFileUploadError,
  } = api.storage.useCompleteCustomerFileUpload();

  const {
    mutateAsync: deleteCustomerFile,
    isPending: isDeletingCustomerFile,
    processedErrorMessage: deleteCustomerFileError,
  } = api.storage.useDeleteCustomerFile();

  const {
    mutateAsync: getCustomerFileDownloadPresignedUrl,
    isPending: isGettingCustomerFileDownloadPresignedUrl,
    processedErrorMessage: getCustomerFileDownloadPresignedUrlError,
  } = api.storage.useGetCustomerFileDownloadPresignedUrl();

  const form = useForm<{
    filePath: string;
    label: string;
  }>({
    initialValues: {
      filePath: "",
      label: "",
    },

    validate: {
      filePath: (value) => {
        if (!value) {
          return "File is required";
        }
      },
    },
  });

  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formData = new FormData(e.target as HTMLFormElement);
    const file = formData.get("fileItem") as File;
    const label = formData.get("fileLabel") as string;

    if (!file) {
      return;
    }

    setIsUploading(true);
    setUploadProgress(0);

    const presignedUrlData = await (async () => {
      try {
        const response = await getCustomerFileUploadPresignedUrl({
          customerId,
          query: {
            contentType: file.type,
            fileNameWithExtension: file.name,
          },
        });

        return response;
      } catch (error) {
        addToast({
          title: t`Napaka`,
          description: t`Prišlo je do napake pri nalaganju datoteke`,
          color: "danger",
        });

        setIsUploading(false);
        setUploadProgress(0);
        refetch();
        form.reset();

        return null;
      }
    })();

    if (!presignedUrlData) {
      return;
    }

    try {
      await uploadFileWithXHR(
        file,
        presignedUrlData.presignedUrl,
        (percent) => {
          setUploadProgress(percent);
        },
      );

      await completeCustomerFileUpload({
        customerId,
        body: {
          fileId: presignedUrlData.fileId,
          isSuccess: true,
          label,
        },
      });

      addToast({
        title: t`Datoteka naložena`,
        description: t`Datoteka je bila uspešno naložena`,
        color: "success",
      });

      refetch();
    } catch (error) {
      addToast({
        title: t`Napaka`,
        description: t`Prišlo je do napake pri nalaganju datoteke`,
        color: "danger",
      });

      await completeCustomerFileUpload({
        customerId,
        body: {
          fileId: presignedUrlData.fileId,
          isSuccess: false,
        },
      });
    } finally {
      setIsUploading(false);
      setUploadProgress(0);
      refetch();
      form.reset();
    }
  };

  const handleDownloadFile = async (fileId: number) => {
    const presignedUrlData = await getCustomerFileDownloadPresignedUrl({
      id: customerId,
      fileId,
      query: {
        asAttachment: true,
      },
    });

    // Create an ephemeral <a> to force download
    const link = document.createElement("a");
    link.href = presignedUrlData.presignedUrl;

    // 'download' attribute will make the browser save the file
    // You can specify a filename here:
    link.download = presignedUrlData.fileName ?? "download";
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  const handlePreviewFile = async (fileId: number) => {
    const presignedUrlData = await getCustomerFileDownloadPresignedUrl({
      id: customerId,
      fileId,
    });

    window.open(presignedUrlData.presignedUrl, "_blank");
  };

  const files = (() => {
    if (isFetching) {
      return Array.from({ length: Number(perPage) }, () => ({
        id: 0,
        name: "",
        size: 0,
        state: { raw: "uploaded", label: t`Naložena` },
        label: "",
        uploaderName: "",
        dateCreated: "",
        isSkeleton: true,
      }));
    }

    if (!data) {
      return [];
    }

    return data.files.map((file) => ({
      ...file,
      isSkeleton: false,
    }));
  })();

  console.log(files);

  return (
    <div className="mt-4">
      {files.length === 0 && (
        <p className="text-center text-sm text-gray-500">
          <Trans>Stranka še nima datotek</Trans>
        </p>
      )}

      <form
        className="flex flex-col gap-2"
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();

          form.validate();

          if (form.isValid()) {
            handleSubmit(e);
          }
        }}
      >
        <LimeInput
          type="file"
          name="fileItem"
          onChange={(e) => {
            form.setFieldValue("filePath", e.target.files?.[0]?.name ?? "");
          }}
          error={form.errors.filePath}
        />

        {isUploading && (
          <Progress
            aria-label="Uploading..."
            value={uploadProgress}
            showValueLabel
            className="mb-2"
            color="primary"
          />
        )}

        <LimeInput
          type="text"
          name="fileLabel"
          label={t`Naziv datoteke`}
          {...form.getInputProps("label")}
        />

        <LimeAlert
          className="mb-1"
          message={getPresignedUrlError ?? completeCustomerFileUploadError}
        />

        <Button
          color="primary"
          variant="bordered"
          startContent={<CloudUpload size={16} />}
          type="submit"
          isLoading={
            isGettingPresignedUrl ||
            isCompletingCustomerFileUpload ||
            isUploading
          }
        >
          <Trans>Naloži datoteko</Trans>
        </Button>
      </form>

      <Divider className="my-4" />

      <div className="mt-2 flex flex-col gap-4">
        {files.map((file) => {
          return (
            <Skeleton isLoaded={!file.isSkeleton} className="rounded-2xl">
              <Card
                key={file.id}
                shadow="sm"
                className={cn({
                  "outline-2 outline-red-500": file.state.raw === "failed",
                })}
              >
                <CardBody>
                  <div>
                    <div>
                      <p className="mb-1 font-medium leading-5">
                        {file.label ?? file.name}
                        {file.label != null && (
                          <span className="ml-1 text-xs font-normal text-gray-500">
                            ({file.name})
                          </span>
                        )}
                      </p>

                      <div className="flex items-end justify-between gap-1">
                        <div>
                          {file.state.raw === "uploaded" && (
                            <span className="text-xs text-gray-500">
                              {filesize(file.size)}
                            </span>
                          )}
                          <p className="text-xs text-gray-500">
                            <Trans>Naložil/a</Trans>: {file.uploaderName}
                          </p>
                          <p className="text-xs text-gray-500">
                            <Trans>Datum</Trans>: {file.dateCreated}
                          </p>
                        </div>

                        <div className="flex gap-2">
                          <Tooltip content={t`Predogled datoteke`}>
                            <Button
                              variant="light"
                              isIconOnly
                              isLoading={
                                isGettingCustomerFileDownloadPresignedUrl
                              }
                              size="lg"
                              onPress={async () => {
                                await handlePreviewFile(file.id);
                              }}
                            >
                              <Eye size={16} />
                            </Button>
                          </Tooltip>

                          <Tooltip content={t`Prenesi datoteko`}>
                            <Button
                              variant="light"
                              isLoading={
                                isGettingCustomerFileDownloadPresignedUrl
                              }
                              isIconOnly
                              size="lg"
                              onPress={async () => {
                                await handleDownloadFile(file.id);
                              }}
                            >
                              <Download size={16} />
                            </Button>
                          </Tooltip>

                          <AlertButton
                            title={t`Izbriši datoteko`}
                            content={t`Ali ste prepričani, da želite izbrisati datoteko?`}
                            tooltip={t`Izbriši datoteko`}
                            buttonProps={{
                              variant: "light",
                              startContent: <Trash size={16} />,
                              color: "danger",
                              isIconOnly: true,
                              size: "lg",
                            }}
                            confirmProps={{
                              label: t`Izbriši datoteko`,
                              onClick: async () => {
                                await deleteCustomerFile({
                                  id: customerId,
                                  fileId: file.id,
                                });

                                refetch();
                              },
                              isLoading: isDeletingCustomerFile,
                            }}
                          ></AlertButton>
                        </div>
                      </div>
                    </div>
                  </div>

                  {file.state.raw !== "uploaded" && (
                    <p
                      className={cn("text-xs text-gray-500", {
                        "font-medium text-red-500": file.state.raw === "failed",
                        "text-sm font-medium text-warning-600":
                          file.state.raw === "pending",
                      })}
                    >
                      {file.state.label}
                    </p>
                  )}
                </CardBody>
              </Card>
            </Skeleton>
          );
        })}

        <LimePagination
          paginationProps={{
            total: data?.pagination.totalPages ?? 1,
            page,
            onChange: setPage,
          }}
          perPageProps={{
            label: t`datotek`,
            values: [5, 10, 20],
            onChange: setPerPage,
            value: perPage,
          }}
        />
      </div>
    </div>
  );
};
