import { LimePagination } from "@/Components/LimePagination";
import PageContentTable from "@/Components/PageContentTable/PageContentTable";
import {
  useGetPaymentsList,
  useGetTaxPayments,
} from "@/lib/api-client/paths/stripe";
import { TextVariant } from "@/types/text-variants";
import { Skeleton, Text } from "@mantine/core";
import { MonthPicker } from "@mantine/dates";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { BiExport } from "react-icons/bi";
import { useOutletContext, useSearchParams } from "react-router";
import type {
  GetPaymentsList,
  GetTaxPayments,
} from "../../../../../server/src/types";
import type { StripeOutletContextProps } from "./Stripe";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
  Button as HeroUIButton,
  Checkbox,
  Button,
} from "@heroui/react";
import { Filter, FilterX } from "lucide-react";
import { Trans } from "@lingui/react/macro";
import { t } from "@lingui/core/macro";
import { LimeAlert } from "@/Components/NextBase/LimeAlert";
import { PaymentDetailsModal } from "./PaymentDetailsModal";
import { StatusBadge } from "./StatusBadge";

const StatusFilter = {
  succeeded: "succeeded",
  failed: "failed",
  processing: "processing",
  refunded: "refunded",
} as const;
type StatusFilter = keyof typeof StatusFilter;
export const PaymentsOverviewTab = () => {
  const { payments } = useOutletContext<StripeOutletContextProps>();
  const { search } = payments;

  const [searchParams, setSearchParams] = useSearchParams();

  const page = searchParams.has("page") ? Number(searchParams.get("page")) : 1;
  const [perPage, setPerPage] = useState("10");

  const [paymentsExportDateRange, setPaymentsExportDateRange] =
    useState<Date | null>(null);

  /**
   * FILTERS
   */
  const filterPaymentStatuses = searchParams.getAll("status");
  const setFilterPaymentStatuses = (statuses: StatusFilter[]) => {
    searchParams.delete("status");
    statuses.forEach((status) => {
      searchParams.append("status", status);
    });
    setSearchParams(searchParams);
  };
  const toggleFilterPaymentStatus = (status: StatusFilter) => {
    const statusFilters = searchParams.getAll("status");
    if (statusFilters.includes(status)) {
      statusFilters.splice(statusFilters.indexOf(status), 1);
    } else {
      statusFilters.push(status);
    }

    searchParams.delete("status");
    statusFilters.forEach((status) => {
      searchParams.append("status", status);
    });
    setSearchParams(searchParams);
  };

  const { data, isLoading, isSuccess } = useGetPaymentsList({
    search,
    page,
    perPage: Number.parseInt(perPage),
    statusFilters:
      filterPaymentStatuses as GetPaymentsList["query"]["statusFilters"],
  });

  const { mutateAsync: getTaxPayments, isPending: isGettingTaxPayments } =
    useGetTaxPayments();

  const exportPayments = async ({
    format,
  }: {
    format: GetTaxPayments["query"]["format"];
  }) => {
    if (!paymentsExportDateRange) {
      return;
    }

    const startDate = dayjs(paymentsExportDateRange)
      .startOf("month")
      .format("YYYY-MM-DD");
    const endDate = dayjs(paymentsExportDateRange)
      .endOf("month")
      .format("YYYY-MM-DD");

    const response = await getTaxPayments({
      startDate,
      endDate,
      format,
    });

    const paymentsString = response.payments;
    const feesString = response.fees;

    if (!paymentsString || !feesString) {
      return;
    }

    downloadFile({
      content: paymentsString,
      type: response.type,
      startDate,
      endDate,
      exportType: "payments",
      format,
    });

    downloadFile({
      content: feesString,
      type: response.type,
      startDate,
      endDate,
      exportType: "fees",
      format,
    });
  };

  const downloadFile = ({
    content,
    type,
    format,
    startDate,
    endDate,
    exportType,
  }: {
    content: string;
    type: string;
    format: "csv" | "xml";
    startDate: string;
    endDate: string;
    exportType: "payments" | "fees";
  }) => {
    const fileType = `${type};charset=utf-8;`;
    const blob = new Blob([content], { type: fileType });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute(
      "download",
      `${exportType}_${startDate}_${endDate}.${format}`,
    );

    document.body.appendChild(link);

    link.click();
    link.parentNode?.removeChild(link);
  };

  const checkIfShouldReturnToFirstPage = () => {
    if (!data) return;

    if (page > 1 && data.pagination.pageCount < page) {
      // setPage(1);
      searchParams.set("page", "1");
      setSearchParams(searchParams);
    }
  };
  useEffect(() => {
    checkIfShouldReturnToFirstPage();
  }, [data]);

  const nonProcessingPayments = data?.paymentsList.filter(
    (payment) => payment.status !== "requires_payment_method",
  );
  const processingPayments = data?.paymentsList.filter(
    (payment) => payment.status === "requires_payment_method",
  );

  return (
    <>
      <div>
        <div className="flex flex-col gap-1">
          <div className="flex justify-between">
            <Popover
              showArrow
              shadow="md"
              onChange={(opened) => {
                if (opened) {
                  setPaymentsExportDateRange(null);
                }
              }}
            >
              <PopoverTrigger>
                <Button
                  variant="flat"
                  startContent={<BiExport />}
                  className="w-fit"
                >
                  Izvozi plačila za računovodstvo
                </Button>
              </PopoverTrigger>
              <PopoverContent>
                <div className="flex flex-col gap-1">
                  <MonthPicker
                    value={paymentsExportDateRange}
                    onChange={setPaymentsExportDateRange}
                  />
                  <div className="flex gap-1">
                    <Button
                      onPress={() => exportPayments({ format: "csv" })}
                      isLoading={isGettingTaxPayments}
                      fullWidth
                      color="primary"
                    >
                      <Trans>Izvozi CSV</Trans>
                    </Button>
                    <Button
                      onPress={() => exportPayments({ format: "xml" })}
                      isLoading={isGettingTaxPayments}
                      fullWidth
                      color="primary"
                    >
                      <Trans>Izvozi XML</Trans>
                    </Button>
                  </div>
                </div>
              </PopoverContent>
            </Popover>

            <Popover placement="bottom-end" showArrow>
              <PopoverTrigger>
                <HeroUIButton isIconOnly variant="flat">
                  {filterPaymentStatuses.length !==
                  Object.keys(StatusFilter).length ? (
                    <FilterX />
                  ) : (
                    <Filter />
                  )}
                </HeroUIButton>
              </PopoverTrigger>
              <PopoverContent>
                <div className="min-w-72 px-1 py-2">
                  <p className="mb-2 text-lg font-medium capitalize">
                    <Trans>Filter</Trans>
                  </p>
                  <div>
                    <div className="mb-4 flex items-center justify-between">
                      <p className="text-base">
                        <Trans>Tipi</Trans>
                      </p>

                      <HeroUIButton
                        color={
                          filterPaymentStatuses.length ===
                          Object.keys(StatusFilter).length
                            ? "primary"
                            : "default"
                        }
                        onPress={() => {
                          if (
                            filterPaymentStatuses.length ===
                            Object.keys(StatusFilter).length
                          ) {
                            setFilterPaymentStatuses([]);
                          } else {
                            setFilterPaymentStatuses(
                              Object.values(StatusFilter),
                            );
                          }
                        }}
                      >
                        <Trans>Izberi vse</Trans>
                      </HeroUIButton>
                    </div>

                    <div className="flex flex-col gap-2 rounded-md bg-[#fafafa] p-2">
                      {[
                        {
                          label: t`Uspešno`,
                          value: "succeeded",
                          checked: filterPaymentStatuses.includes("succeeded"),
                          onChange: () =>
                            toggleFilterPaymentStatus("succeeded"),
                        },
                        {
                          label: t`Neuspešno`,
                          value: "failed",
                          checked: filterPaymentStatuses.includes("failed"),
                          onChange: () => toggleFilterPaymentStatus("failed"),
                        },
                        {
                          label: t`V obdelavi`,
                          value: "processing",
                          checked: filterPaymentStatuses.includes("processing"),
                          onChange: () =>
                            toggleFilterPaymentStatus("processing"),
                        },
                        {
                          label: t`Povrnjeno`,
                          value: "refunded",
                          checked: filterPaymentStatuses.includes("refunded"),
                          onChange: () => toggleFilterPaymentStatus("refunded"),
                        },
                      ]?.map((row) => {
                        return (
                          <Checkbox
                            isSelected={row.checked}
                            onValueChange={row.onChange}
                            value={row.value}
                          >
                            {row.label}
                          </Checkbox>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </PopoverContent>
            </Popover>
          </div>

          {isLoading && (
            <div className="mt-11 flex flex-col gap-1">
              <Skeleton height={25} width={"100%"} />
              <Skeleton height={25} width={"100%"} />
              <Skeleton height={25} width={"100%"} />
              <Skeleton height={25} width={"100%"} />
              <Skeleton height={25} width={"100%"} />
              <Skeleton height={25} width={"100%"} />
            </div>
          )}

          {isSuccess &&
            filterPaymentStatuses.includes("processing") &&
            processingPayments &&
            processingPayments.length > 0 && (
              <PageContentTable
                checkboxSelectors={false}
                idValue={"date"}
                objects={processingPayments}
                values={
                  processingPayments.map((payment) => [
                    {
                      label: t`Status`,
                      value: <StatusBadge payment={payment} />,
                    },
                    {
                      label: t`Naročilo`,
                      value: payment.payment,
                    },
                    {
                      label: t`Datum`,
                      value: payment.date,
                    },
                    {
                      label: t`Znesek`,
                      value: `${payment.amountFormatted}`,
                    },
                  ]) as never[]
                }
                selectRow={(
                  row: GetPaymentsList["response"]["paymentsList"][0],
                ) => {
                  searchParams.set("uid", row.userAppointmentId.toString());
                  setSearchParams(searchParams);
                }}
                isLoading={false}
                isMobile={false}
                toggleAllSelected={() => {}}
                toggleSelectedObject={() => {}}
                perPage={10}
              />
            )}

          {isSuccess &&
            nonProcessingPayments &&
            nonProcessingPayments.length > 0 && (
              <PageContentTable
                checkboxSelectors={false}
                idValue={"date"}
                objects={nonProcessingPayments}
                values={
                  nonProcessingPayments.map((payment) => [
                    {
                      label: t`Status`,
                      value: <StatusBadge payment={payment} />,
                    },
                    {
                      label: t`Naročilo`,
                      value: payment.payment,
                    },
                    {
                      label: t`Datum plačila`,
                      value: payment.date,
                    },
                    {
                      label: t`Znesek`,
                      value: `${payment.amountFormatted}`,
                    },
                  ]) as never[]
                }
                selectRow={(
                  row: GetPaymentsList["response"]["paymentsList"][0],
                ) => {
                  searchParams.set("uid", row.userAppointmentId.toString());
                  setSearchParams(searchParams, { replace: true });
                }}
                isLoading={false}
                isMobile={false}
                toggleAllSelected={() => {}}
                toggleSelectedObject={() => {}}
                perPage={10}
              />
            )}

          {isSuccess && data.paymentsList.length === 0 && (
            <Text variant={TextVariant.Body} ta={"center"}>
              <Trans>Ni plačil</Trans>
            </Text>
          )}

          {isSuccess && data.includesPaymentsBefore17Oct && (
            <LimeAlert
              title={t`Obvestilo`}
              message={
                <Trans>
                  V seznamu so prikazana plačila od 17. 10. 2023 dalje. Za
                  podatke o plačilih pred tem datumom, prosimo, da nas
                  kontaktirate.
                </Trans>
              }
            ></LimeAlert>
          )}

          <LimePagination
            page={page}
            setPage={(page) => {
              searchParams.set("page", page.toString());
              setSearchParams(searchParams);
            }}
            perPage={perPage}
            setPerPage={setPerPage}
            pageCount={isSuccess ? data.pagination.pageCount : 1}
            disabled={isLoading}
            label={t`plačil`}
            maxPerPage={"100"}
          />
        </div>
      </div>

      <PaymentDetailsModal
        userAppointmentId={
          searchParams.has("uid") ? Number(searchParams.get("uid")) : undefined
        }
        onClose={({ refetchData }) => {
          searchParams.delete("uid");
          setSearchParams(searchParams);
        }}
      />
    </>
  );
};
